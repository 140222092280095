<div id="plots" class="plots">
    <div class="plots__close">
        <button mat-mini-fab class="plots__toggles__close" color="basic" (click)="closeBottomSheet()">
            <mat-icon aria-hidden="false" aria-label="Close bottom sheet">close</mat-icon>
        </button>
    </div>

        <div class="plots__toggles">
            <div *ngFor="let item of legend | async">
                <div class="plots__toggles__toggle" [style.background-color]="item.options.color">
                    <div class="plots__toggles__toggle__slide">
                        <mat-slide-toggle [(ngModel)]="item.options.enabled" (change)="toggleChange()"></mat-slide-toggle>
                    </div>

                    <div class="plots__toggles__toggle__text">{{item.options.name}} ({{item.options.unit}})</div>
                </div>
            </div>
        </div>


    <div class="plots__charts">
        <cdk-plot id="my-plot" [data]="series | async"></cdk-plot>
    </div>
</div>
