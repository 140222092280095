import { Component, Input,} from '@angular/core';
import { WaterCoverage } from '../../elements/watercoverage';
import { CardComponent } from '../card-component';


@Component({
  selector: 'app-watercoverage',
  templateUrl: './watercoverage.component.html',
  styleUrls: ['./watercoverage.component.scss']
})
export class WaterCoverageComponent implements CardComponent {
  @Input('data') data !: WaterCoverage; 
  
  //title = this.data["category"]
  constructor(){
    console.log(this.data)
    //debugger
  }
  link = ""
  var = ""

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["0area", ["Område nummer", ("")]],
    ["12id", ["Værk nummer", ""]],
    ["11name", ["Navn", ""]],
    ["10web", ["Link", ""]],
    ["22id", ["Værk nummer", ""]],
    ["21name", ["Navn", ""]],
    ["20web", ["Link", ""]],
    ["32id", ["Værk nummer", ""]],
    ["31name", ["Navn", ""]],
    ["30web", ["Link", ""]],    
    ["42id", ["Værk nummer", ""]],
    ["41name", ["Navn", ""]],
    ["40web", ["Link", ""]],    
    ["52id", ["Værk nummer", ""]],
    ["51name", ["Navn", ""]],
    ["50web", ["Link", ""]],    
    ["62id", ["Værk nummer", ""]],
    ["61name", ["Navn", ""]],
    ["60web", ["Link", ""]],    
    ["72id", ["Værk nummer", ""]],
    ["71name", ["Navn", ""]],
    ["70web", ["Link", ""]],    
    ["82id", ["Værk nummer", ""]],
    ["81name", ["Navn", ""]],
    ["80web", ["Link", ""]],    
    ["92id", ["Værk nummer", ""]],
    ["91name", ["Navn", ""]],
    ["90web", ["Link", ""]],
  ])
  
  
  isMapped(property: string, property2): boolean {
    if (property.includes("web")){
      //console.log("Link found", property2)
      this.link = property2
      return false
    }
    else{
      return this.map.has(property)
    }
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }

  hasLink(property): boolean {
    return ((property.includes("web")))
  }

  hasName(property): boolean {
    return ((property.includes("name")))
  }

  getLink(property): string {
    property = this.link;
    return this.link;
  }

  linkClicked(property){
    console.log("Link clicked : " + property),
    window.open(property)
  }

  isThis(property1, property2){
    return (property1 == property2)
  }

  containsThis(property1, property2){
    return ((property1.includes(property2)))
  }
}