import { SelectionModel } from "@angular/cdk/collections"
import { NestedTreeControl } from "@angular/cdk/tree"
import { MatTreeNestedDataSource } from "@angular/material/tree"

export class TreeNode<T> {
    private _data: T
    private _children: TreeNode<T>[] = []

    constructor(data: T, children?: TreeNode<T>[]) {
        this._data = data
        this._children = children
    }

    public get data() { return this._data }
    public get children() { return this._children }
}

export class Tree<T> {

    private _root = new TreeNode(null, [])
    private _control = new NestedTreeControl<TreeNode<T>>(node => node.children)
    private _dataSource = new MatTreeNestedDataSource<TreeNode<T>>()
    private _selection = new SelectionModel<TreeNode<T>>(true)

    public get root() { return this._root }
    public get control() { return this._control }
    public get dataSource() { return this._dataSource }
    public get selection() { return this._selection }

    constructor() {
        this._dataSource.data = this._root.children
    }

    private _refreshTree(): void {
        const data = this._dataSource.data
        this._dataSource.data = null
        this._dataSource.data = data
    }

    public add(parent: TreeNode<T>, node: TreeNode<T>) {
        parent.children.push(node)
        this._refreshTree()
    }

    public hasLayers(_: number, node: TreeNode<T>): boolean {
        return node?.children.length > 0
    }

    public toggleNode(node: TreeNode<T>, nested: boolean): void {
        // Toggle node itself
        this._selection.toggle(node)

        // Traverse downwards and update descendants
        const descendants = this._control.getDescendants(node)

        this._selection.isSelected(node)
            ? this._selection.select(...descendants)
            : this._selection.deselect(...descendants)

        // Traverse upwards and update parents
        let parent = this.getParentNode(node)

        while (parent) {
            this.checkRootNodeSelection(parent)
            parent = this.getParentNode(parent)
        }
    }

    public checkRootNodeSelection(node: TreeNode<T>): void {
        const nodeSelected = this._selection.isSelected(node)
        const descendants = this._control.getDescendants(node)
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every(child => {
            return this._selection.isSelected(child)
            })

        if (nodeSelected && !descAllSelected) {
            this._selection.deselect(node)
        } else if (!nodeSelected && descAllSelected) {
            this._selection.select(node)
        }
    }

    public getParentNode(node: TreeNode<T>, current: TreeNode<T> | undefined = undefined): TreeNode<T> | null {
        const descendants = (current === undefined) ? this._dataSource.data : this._control.getDescendants(current)

        for (let i=0; i<descendants.length; i++) {
            const d = descendants[i]
            
            if (d === node) { return current }

            const result = this.getParentNode(node, d)
            if (result) { return result }
        }

        return null;
    }

    public descendantsAllSelected(node: TreeNode<T>): boolean {
        const descendants = this._control.getDescendants(node)
        const descAllSelected = descendants.length > 0 && descendants.every(child => this._selection.isSelected(child))

        return descAllSelected
    }

    public descendantsPartiallySelected(node: TreeNode<T>): boolean {
        const descendants = this._control.getDescendants(node)
        const result = descendants.some(child => this._selection.isSelected(child))

        return result && !this.descendantsAllSelected(node)
    }
}