<h2>Værker der leverer vand til området</h2>

<div *ngFor="let m of data.getProperties() | keyvalue">
    <div *ngIf="isMapped(m.key, m.value) && m.value">
        <div *ngIf="containsThis(m.key, 'name'); else notName">
            <div class="sidenav__modal__content__key">{{ getName(m.key) }}</div>
            <div class="sidenav__modal__content__value">
                <a href="{{link}}" target="_blank">{{ m.value }}{{ getUnit(m.key) }}</a>
            </div>
        </div>
        <div *ngIf="isThis(m.key, '0area')"><br><hr></div>
        <div *ngIf="containsThis(m.key, 'id')"><br><hr></div>
    </div>
    <ng-template #notName>    
        <div class="sidenav__modal__content__key">{{ getName(m.key) }}</div>
        <div class="sidenav__modal__content__value">{{ m.value }}{{ getUnit(m.key) }}</div>
    </ng-template>
</div>

