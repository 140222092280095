<h2>Bygningsdetaljer</h2>

<style>
    div {
      white-space: pre-wrap;
    }
  </style>

<div *ngFor="let m of data.getProperties() | keyvalue">
    <div *ngIf="isMapped(m.key, m.value)">
        <div class="sidenav__modal__content__key">{{ getName(m.key) }}</div>
        <div class="sidenav__modal__content__value">{{ m.value }}{{ getUnit(m.key) }}</div>
    </div>
</div>

<div *ngIf="number_of_graphs > 0" class="leaflet-bottom" style="right: 135px; margin: 1em;">
  <div *ngIf="graphClosed()">
    <button class="map-plot-button" mat-raised-button (click)="openGraph()">Vis grafer</button>
  </div>
</div>

<div *ngIf="graphOpen" [@showGraphContainer] id="graphContainer" [style.bottom.px]="graph_container_bottom" [style.height.px]="graph_container_height">
  <div *ngFor="let graph of this.data_sets">
    <div graph. echarts [options]="graph.getEchartsoption()" class="chart"></div>
  </div>
  <div class="leaflet-bottom" style="right: 135px; margin: 1em;">
      <button class="map-plot-button2" mat-raised-button (click)="closeGraph()">Luk grafer</button>
  </div>
</div>
