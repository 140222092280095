import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da'

// Custom
import { LayerManager } from '../models/layer-manager';
import { Tree, TreeNode } from '../tree'
import { InfoModalManager } from '../models/info-modal-manager';

// Services
import { DataService } from '../services/data/data.service';
import { DomainGroup, DomainService  } from '../services/domain/domain.service';
import { LayerService } from '../services/layer/layer.service';
import { Logger, LoggingService } from '../services/logging/logging.service';

// Data transfer objects
import { DomainDTO } from '../models/dtos/domain-dto';

// Map libraries
import * as L from 'leaflet';
import { PeriodService } from '../services/period/period.service';
import { GestureHandling } from 'leaflet-gesture-handling';
import { LayerDTO } from '../models/dtos/layer-dto';
import { ElementService } from '../services/element/element.service';
import { PeriodDTO } from '../models/dtos/period-dto';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../controls/bottom-sheet/bottom-sheet.component';
import { Base } from '../models/elements/base';
import { MappingService } from '../services/mapping/mapping.service';
import { SeriesManager } from '../models/series-manager';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormControl } from '@angular/forms';

import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SerieService } from '../services/serie/serie.service';
import { SeriesUtils } from '../utils/SeriesUtils';
import { CurrentDomainService } from '../services/current-domain.service';

registerLocaleData(localeDa, 'da-DK')

interface EnvData {
    wind_dir: number
    wind_speed: number
    temp: number,
    co2: number
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild(MatAutocomplete) auto!: MatAutocomplete;

    private static DEFAULT_BOUNDS: L.LatLngBounds = new L.LatLngBounds([57.944566, 5.770159], [54.377897, 15.581988])

    renderTicks = SeriesUtils.renderTicks

    title = 'rksk-frontend'
    isLayerSelectOpen = false
    // domains: DomainDTO[] = []
    periods: any[] = []
    markerPopupContent = null
    selectedDomain: BehaviorSubject<DomainDTO> = new BehaviorSubject(null);
    selectedPeriod: BehaviorSubject<any> = new BehaviorSubject(PeriodService.DEFAULT)
    selectedData: BehaviorSubject<EnvData> = new BehaviorSubject(undefined)
    layerTree: Tree<LayerDTO> = new Tree()

    domainFormControl = new FormControl()
    domainGroupsFiltered: Observable<DomainGroup[]> = null;

    private _serieManager: SeriesManager
    private _matBottomSheetRef: MatBottomSheetRef
    private _layerManager: LayerManager
    private _map: L.Map
    private _logger: Logger

    private _zoomObserver: BehaviorSubject<L.LatLngBounds> = new BehaviorSubject(AppComponent.DEFAULT_BOUNDS)

    timeout: number = 0

    // domain_chosen: boolean = false // for domain border automation
    // chosen_domain: number = null

    show_copylink_button: boolean = false
    layer_string: string = ""
    userActivity;   // Anders: What type is this!?
    userInactive: Subject<any> = new Subject();

    constructor(
        private _loggingService: LoggingService,
        private _domainService: DomainService,
        private _periodService: PeriodService,
        private _layerService: LayerService,
        private _elementService: ElementService,
        private _dataService: DataService,
        private _infoModalManager: InfoModalManager,
        private _bottomSheet: MatBottomSheet,
        private _mappingService: MappingService,
        private _clipboard: Clipboard,
        private _route: ActivatedRoute,
        private _serieService: SerieService,
        private _currentDomainService: CurrentDomainService
    ) {
        this._logger = _loggingService.getLogger(AppComponent.name)
        this._layerManager = new LayerManager(_elementService, _loggingService, _infoModalManager, _mappingService)
        this._serieManager = new SeriesManager(_serieService, _dataService, _loggingService)
        this.userInactive.subscribe(() => {
            window.location.reload();
        });
    }

    ngOnInit(): void {
        this.initMap()

        this._zoomObserver.subscribe(bounds => {
            this._map.fitBounds(bounds)
        })

        this.domainGroupsFiltered = this.domainFormControl.valueChanges.pipe(
            startWith(''),
            debounceTime(100),
            switchMap(value => {
                    return this._domainService.queryDomainsByName(value)
            })
        )

        this.selectedDomain.subscribe(domain => {
            this._layerManager.domain = domain

            // if (municipality.code != -1) {
            //     this.municipality_chosen = true
            // } else {
            //     this.municipality_chosen = false
            // }

            // this.chosen_municipality = municipality.code

            if (domain && domain.bounds) {
                let bounds = domain.bounds

                this._zoomObserver.next(new L.LatLngBounds(
                    [bounds.north_lat, bounds.west_lon],
                    [bounds.south_lat, bounds.east_lon]
                ))
                this._currentDomainService.update(domain)
            }
            this._serieManager.domain = domain
        })

        this.selectedPeriod.subscribe((period: PeriodDTO) => {
            this._serieManager.period = period
        })

        this.initDropdowns()
        this.initLayerTree()
    }

    private initMap(): void {
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

        let options: any =  {
            center: [56.1762315, 10.6760735],   // Center coordinates for Denmark
            zoom: 1,
            zoomControl: true,
            gestureHandling: false
        }

        this._map = new L.Map('map', options)

        this._map.zoomControl.setPosition('topright')
        this._layerManager.baseLayer.addTo(this._map)

        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png', {}).addTo(this._map)

        this._layerManager.update.subscribe(message => {
            this._logger.info(`Layer manager update: [${message}]`)
        })
    }

    private initDropdowns(): void {

        //** LINKBUTTON_START */

        // Attach route listener
        this._route.queryParams.subscribe(params => {
            this.show_copylink_button = (params.linkbutton > 0)
            this.layer_string = params.layers || "";

            if (params.default) {           //for municipality border automation

                this._domainService.getDomainByCode(params.default).subscribe(domain => {
                    if (domain) {
                        this.domainFormControl.patchValue(domain)

                        const option = { value: domain }

                        const event = new MatAutocompleteSelectedEvent(
                            this.auto,
                            option as any
                        )
                        this.onDomainSelected(event)
                    }
                })

                // this.domain_chosen = true
                // this.chosen_domain = params.default
            }

            if (params.timeout > 0) {
                this.timeout = params.timeout * 1000
            }
        })

        //** LINKBUTTON_END */

        this._periodService.getPeriods().subscribe(periods => {
            this.periods = periods
            this.selectedPeriod.next(this.periods[2])
        })
    }

    private initLayerTree(): void {
        this.layerTree.selection.changed.subscribe(change => {
            change.removed.forEach(node => {
                this._layerManager.remove(node.data)
            })

            change.added.forEach(node => {
                this._layerManager.add(node.data)
            })
        })

        const load = (parent: TreeNode<LayerDTO>) => {
            this._layerService.getLayers(parent.data).subscribe(dtos => {
                const layers: string[] = this.layer_string.split(",")
                dtos.forEach(dto => {
                    const node = new TreeNode<LayerDTO>(dto, [])
                    this.layerTree.add(parent, node)
                    if(layers.includes(node.data.id)){
                            this.layerTree.toggleNode(node, true)
                    }
                    load(node)
                })
            })
        }

        load(this.layerTree.root)
    }

    openMoreInfoModal(element = null): void {
        this._infoModalManager.openMoreInfoModal(element)
    }

    closeMoreInfoModal(): void {
        this._infoModalManager.closeMoreInfoModal()
        this.markerPopupContent = null
    }

    getInfoModalOpen(): boolean {
        return this._infoModalManager.isMoreInfoOpen
    }

    getSelectedElement(): Base {
        return this._infoModalManager.getSelectedElement()
    }

    onTimeChanged(event: any, done: boolean): void {
        if (done) {
            event.source._elementRef.nativeElement.blur()
        }

        let ed: EnvData = {
            wind_dir:   this._serieManager.getValue(SeriesUtils.WEATHER_KEY_WIND_DIR, event.value),
            wind_speed: this._serieManager.getValue(SeriesUtils.WEATHER_KEY_WIND_SPEED, event.value),
            temp:       this._serieManager.getValue(SeriesUtils.WEATHER_KEY_WIND_TEMP, event.value),
            co2:        this._serieManager.getValue(SeriesUtils.WEATHER_KEY_EMISSION, event.value)
        }

        this.selectedData.next(ed)
    }

    formatTimeLabel = (value: number): string => {
        if (this._serieManager.hasData()) {
            const datepipe: DatePipe = new DatePipe('en-US')
            let formattedDate = datepipe.transform(value, 'dd-MM-YYYY HH:mm:ss')

            return formattedDate
        } else {
            return '???'
        }
    }

    getTimeMin(): Observable<number> { return this._serieManager.first }
    getTimeMax():  Observable<number> { return this._serieManager.last }
    getTimeStep(): Observable<number> { return this._serieManager.step }

    hasData(): boolean {
        return this._serieManager.hasData()
    }

    openBottomSheet(): void {
        
        this._matBottomSheetRef = this._bottomSheet.open(BottomSheetComponent, {
            data: this._serieManager.getSeries(),
            hasBackdrop: false
        });
        this._infoModalManager.setMatBottomSheetRef(this._matBottomSheetRef);
        
    }

    handleBackdropClick(): void {
        this.closeMoreInfoModal()
    }

    activityTracker() {
        clearTimeout(this.userActivity);
        if (this.timeout) {

            this.userActivity = setTimeout(
                () => this.userInactive.next(undefined),
                this.timeout
            );
        }
    }

    @HostListener('window:keydown')
    @HostListener('window:mousemove')
    @HostListener('window:click')
    refreshUserState() {
        this.activityTracker();
    }

    copylink_clicked() {
        const idList: string[] = []
        for (let i = 0; i < this.layerTree.selection.selected.length; i++) {
            idList.push(this.layerTree.selection.selected[i].data.id)
        }

        let output: string = window.location.origin
        this._logger.info(window.location.origin)

        /** TEMPORARY */
        const chosen_domain = this.domainFormControl.value
        /** TEMPORARY */

        if (chosen_domain != null) {
            output = output.concat("/?default=", this.domainFormControl.value.meta.code)
        } else if (this.timeout > 0) {
            output = output.concat("/?")
        }

        if (this.timeout > 0 && chosen_domain != null) {
            let delay: number = this.timeout / 1000
            output = output.concat("&timeout=", delay.toString())
        } else if (this.timeout > 0 && chosen_domain == null) {
            output = output.concat("timeout=", this.timeout.toString())
        }

        if (this.timeout == 0 && chosen_domain == null) {
            output = output.concat("/?")
        } else {
            output = output.concat("&")
        }

        output = output.concat("layers=", idList.toString())
        this._clipboard.copy(output);
    }

    sourcelink_clicked(){
        window.open('https://www.centerdenmark.com/da/datakilder')
    }

    onDomainSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedDomain.next(event.option.value)
    }

    domainToString(domain: DomainDTO) {
        return domain ? domain.name : '';
    }
}
