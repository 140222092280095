import { LatLng, Layer, Polygon } from "leaflet";
import { ElementBuilder } from "../builder";
import { ElementDTO } from "../dtos/element-dto";
import { DHCoverageDTO } from "../dtos/dhcoverage-dto";
import { MappingService } from "src/app/services/mapping/mapping.service";
import { InfoModalManager } from "../info-modal-manager";

export class PolygonBuilder implements ElementBuilder {
    constructor(
        private _infoModalManager: InfoModalManager,
        private mappingService: MappingService
    ) { }

    build(element: DHCoverageDTO): Layer {
        const coords: LatLng[] = element.geo['coords'].map<L.LatLng>(point => {
            return new LatLng(point[1], point[0])
        })
        let fill = '#008080'
        let line = '#008080'
        let year: number = new Date().getFullYear()
        if(element.meta['category'] == 'dh_coverage' && element.meta['completion'] >= year) { fill = '#b5cb99', line = '#568557' }
        if(element.meta['category'] == 'dh_coverage' && element.meta['completion'] <  year) { fill = '#023825', line = '#568557' }

        if(element.meta['category'] == 'gas_coverage' && element.meta['completion'] >= year) { fill = '#ffff4c', line = '#ffb900' }
        if(element.meta['category'] == 'gas_coverage' && element.meta['completion'] <  year) { fill = '#ffff4c', line = '#ffb900' }

        if(element.meta['category'] == 'ww_coverage' && element.meta['code'] == 1) { fill = '#3c3aae', line = '#3c3aae' }   //OK = 1 -> Fælleskloakeret (spildevand og overfladevand løber i samme ledning)
        if(element.meta['category'] == 'ww_coverage' && element.meta['code'] == 2) { fill = '#3839ff', line = '#3839ff' }   //OK = 2 -> Separatkloakeret (spildevand og overfladevand løber i hver sin ledning)
        if(element.meta['category'] == 'ww_coverage' && element.meta['code'] == 3) { fill = '#7f80ff', line = '#7f80ff' }   //OK = 3 -> Spildevandskloakeret (der afledes kun spildevand)
        if(element.meta['category'] == 'ww_coverage' && element.meta['code'] == 4) { fill = '#afaeff', line = '#afaeff' }   //OK = 4 -> overfladevandskloakeret (der afledes kun overfladevand)
        if(element.meta['category'] == 'ww_coverage' && element.meta['code'] >  4) { fill = '#ebecff', line = '#ff0000' }   //OK > 4 -> Ukloakeret eller Anden

        if(element.meta['category'] == 'water_coverage') { fill = '#0000ff', line = '#0000ff' }

        let polygon = new Polygon(coords, {
            interactive: true,
            weight: 1,
            fillOpacity: 0.3,
            fillColor: fill,
            color: line
        })

        polygon.on('click', e => {
            this._infoModalManager.openMoreInfoModal(element)
            // console.log("Polygon clicked")
        })
        return polygon
    }

}
