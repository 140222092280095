import { ElementDTO } from "./dtos/element-dto";
import * as L from 'leaflet';
import { ElementBuilder } from "./builder";

export class ElementFactory {

    private _builders: Map<string, ElementBuilder> = new Map()

    register(type: string, builder: ElementBuilder) {
        this._builders.set(type, builder)
    }

    build(element: ElementDTO): L.Layer | null {

        if (this._builders.has(element.type) && element.geo) {
            const builder: ElementBuilder = this._builders.get(element.type)
            return builder.build(element)
        }

        return null
    }

}