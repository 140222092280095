import { Options } from "../controls/cdk-plot/cdk-plot.component"
import { SerieKey } from "../models/serie-key"
import { TMap } from "../tmap"

export class Color {
    constructor(public r: number, public g: number, public b: number) { }
}

export class SeriesUtils {
    static renderTicks(args: any): void {
        let li: any = args.ticksWrapper.getElementsByClassName('e-large')
        let remarks: any = ['Very Poor', 'Poor', 'Average', 'Good', 'Very Good', 'Excellent']
        for (let i: number = 0; i < li.length; ++i) {
            (li[i].querySelectorAll('.e-tick-both')[1] as HTMLElement).innerText = remarks[i]
        }
    }

    public static readonly WEATHER_KEY_WIND_DIR = {
        category: 'wind_direction',
        data_source: 'DMI',
        process_type: 'weather'
    }

    public static readonly WEATHER_KEY_WIND_SPEED = {
        category: 'wind_speed',
        data_source: 'DMI',
        process_type: 'weather'
    }

    public static readonly WEATHER_KEY_WIND_TEMP = {
        category: 'temperature',
        data_source: 'DMI',
        process_type: 'weather'
    }

    public static readonly WEATHER_KEY_EMISSION = {
        category: 'co2_per_production',
        data_source: 'electricity',
        process_type: 'emission'
    }
}
