import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from "src/app/services/logging/logging.service";
//import { log } from 'console';
import { Observable, of } from 'rxjs';
import { DataDTO } from 'src/app/models/dtos/data-dto';
import { SourceDTO, id_object } from 'src/app/models/dtos/source-dto';
//import { map } from 'leaflet';
import { map } from 'rxjs/operators';
import { TranslationDTO } from 'src/app/models/dtos/translation-dto';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    _loggingService: LoggingService
    ) { 
    this._logger = _loggingService.getLogger(DataService.name)
  }
  private _logger: Logger

  getData(source: any, hours: number): Observable<DataDTO[]> {

    const options = { params: new HttpParams()
        .set('source', JSON.stringify(source))
        .set('hours', hours.toString())
    }
    return this.http.get<DataDTO[]>(`/api/v1/data`, options)
  }

  getLowResData(source: any, hours: number): Observable<DataDTO[]> {
    const options = { params: new HttpParams()
        .set('source', JSON.stringify(source))
        .set('hours', hours.toString())
    }
    return this.http.get<DataDTO[]>(`/api/v1/lowresdata`, options)
  }

  getSources(key: string, value: string): Observable<SourceDTO[]> {
    this._logger.log("options: key: " + key + " value: " + value)
    const options = { params: new HttpParams()
        .set('key', key)
        .set('value', value)
    }
    return this.http.get<id_object[]>(`/api/v1/source`, options).pipe(map((id_objects: id_object[]) => {
      return id_objects.map((id_object: id_object) => {
        return id_object._id
      })
    }))
  }

  getTranslation(language: string, category: string, data_source: string, process_type: string): Observable<TranslationDTO[]> {
    const options = { params: new HttpParams()
        .set('language', language)
        .set('category', category)
        .set('data_source', data_source)
        .set('process_type', process_type)
    }
    return this.http.get<TranslationDTO[]>(`/api/v1/translation`, options)
  }
}

