import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from "src/app/services/logging/logging.service";
//import { log } from 'console';
import { Observable, Subject, of } from 'rxjs';
import { Point, PointSeries, PointGroup } from 'src/app/models/object-classes/Point';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  private _winddirection: PointSeries
  private _windspeed: PointSeries
  private _temperature: PointSeries
  private _co2: PointSeries
  private _timestamp: Date
  public winddirection = new Subject<number>()
  public winddirection$: Observable<number> = this.winddirection.asObservable();
  public windspeed = new Subject<number>()
  public windspeed$: Observable<number> = this.windspeed.asObservable();
  public temperature = new Subject<number>()
  public temperature$: Observable<number> = this.temperature.asObservable();
  public co2 = new Subject<number>()
  public co2$: Observable<number> = this.co2.asObservable();

  constructor( ) { }

  WinddirectionExists() { return this._winddirection != null }
  WindspeedExists() { return this._windspeed != null }
  TemperatureExists() { return this._temperature != null }
  CO2Exists() { return this._co2 != null }

  getWindDirection() { 
    return this._winddirection?.getValueFromTimestamp(this._timestamp) 
  }
  getWindSpeed() { 
    return this._windspeed?.getValueFromTimestamp(this._timestamp) 
  }
  getTemperature() { 
    return this._temperature?.getValueFromTimestamp(this._timestamp)
  }
  getCO2() { 
    return this._co2?.getValueFromTimestamp(this._timestamp) 
  }
  

  clearAll() {
    this._winddirection = null
    this._windspeed = null
    this._temperature = null
    this._co2 = null
    this._timestamp = null
    //this.co2.complete()
    //this.temperature.complete()
    //this.windspeed.complete()
    //this.winddirection.complete()
  }
  setWindDirection(winddirection: PointSeries) { this._winddirection = winddirection }
  setWindSpeed(windspeed: PointSeries) { this._windspeed = windspeed }
  setTemperature(temperature: PointSeries) { this._temperature = temperature }
  setCO2(co2: PointSeries) { this._co2 = co2 }
  updateTimestamp(timestamp: any) { 
    this._timestamp = timestamp 
    this.co2.next(this.getCO2());
    this.temperature.next(this.getTemperature());
    this.windspeed.next(this.getWindSpeed());
    this.winddirection.next(this.getWindDirection());
  }
}

