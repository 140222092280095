import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { DataService } from "../services/data/data.service";
import { Logger, LoggingService } from "../services/logging/logging.service";
import { DomainDTO } from "./dtos/domain-dto";
import { PeriodDTO } from "./dtos/period-dto";
import { Serie } from "./serie";
import { SerieService } from "../services/serie/serie.service";
import { LayerDTO } from "./dtos/layer-dto";
import { Point } from "./point";
import { TMap } from "../tmap";
import { JSONUtils } from "../utils/JSONUtils";

export class SeriesManager {

    first: BehaviorSubject<number> = new BehaviorSubject(0)
    last:  BehaviorSubject<number> = new BehaviorSubject(0)
    step:  BehaviorSubject<number> = new BehaviorSubject(3_600_000)

    private _series: TMap<any, Serie> = new TMap()
    private _seriesObservable: BehaviorSubject<Serie[]> = new BehaviorSubject([])

    private _domain: DomainDTO | undefined = undefined
    private _period: PeriodDTO | undefined = undefined

    private _logger: Logger

    constructor(
        private _serieService: SerieService,
        private _dataService: DataService,
        private _loggingService: LoggingService) {

        this._logger = _loggingService.getLogger(SeriesManager.name)

        this.clear()
    }

    public getValue(key: any, timestamp: number): number | undefined {

        key['municipality_code'] = parseInt(this._domain.meta.code)   // FIXME Temporary hack

        const k = JSONUtils.sortObject(key)
        const serie = this._series.get(k)

        return serie?.getValue(timestamp)
    }

    public hasData(): boolean {
        return Array.from(this._series.values()).some((serie, i, a) => {
            return serie.hasData()
        })
    }

    private clear(): void {
        this._logger.info('Clear')
        this._series.clear()
        this._seriesObservable.next(Array.from(this._series.values()))

        if (this._domain) {
            const target = {
                domain: this._domain.id
            }

            this._serieService.getSerie(this._domain.id).subscribe(series => { 
                series.forEach(serie => {
                    this._series.set(serie.source, new Serie(JSON.stringify(serie.source), serie.meta))
                })

                this._seriesObservable.next(Array.from(this._series.values()))
                this.updateData()
            })
        }
    }

    private updateData() {
        for (let e of this._series.entries()) {

            const source = JSON.parse(e[0])
            const serie = e[1]

            if (!this._domain) {
                serie.update([])
                this._seriesObservable.next(Array.from(this._series.values()))
            } else {
                this._logger.info('Update data')

                this._dataService.getData(source, this._period.hours).subscribe(data => {
                    this._dataService.getData(
                        source,
                        this._period.hours
                    ).subscribe(data => {
                        let mapped: Point[] = data.map(v => new Point(new Date(v.timestamp).getTime(), v.value))

                        serie.update(mapped.reverse())
                        this._logger.info('Updated =>', serie)
                        this.updateBounds() // Called repeatedly (currently necessary due to async calls to dataservice)

                        this._seriesObservable.next(Array.from(this._series.values()))
                    })
                })
            }
        }
    }

    private updateBounds() {
        let f = Array.from(this._series.values()).map(serie => serie.first).filter(v => v > 0)
        let l = Array.from(this._series.values()).map(serie => serie.last).filter(v => v > 0)

        let _first = f.length > 0 ? Math.min(...f) : 0
        let _last = l.length > 0 ? Math.max(...l) : 0

        this.first.next(_first)
        this.last.next(_last)

        this._logger.info(`Bounds: ${_first} <-> ${_last}`)
    }

    public getSeries(): Observable<Serie[]> {
        return this._seriesObservable
    }

    public set domain(domain: DomainDTO) {
        this._domain = domain
        this._logger.info('Domain => ', domain)
        this.clear()
        this.updateData()
    }

    public set period(period: PeriodDTO) {
        this._period= period
        this._logger.info('Period => ', period)
        this.updateData()
    }
}
