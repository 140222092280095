import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SerieDTO } from 'src/app/models/dtos/serie-dto';

@Injectable({
  providedIn: 'root'
})
export class SerieService {

    constructor(private http: HttpClient) { }

    getSerie(target: any): Observable<SerieDTO[]> {
        const options = { params: new HttpParams()
            .set('target', JSON.stringify(target))
        }

        return this.http.get<SerieDTO[]>(`/api/v1/serie`, options)
    }
}
