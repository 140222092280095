import { isDevMode, Injectable } from '@angular/core';

export class Logger {
  constructor(private _class: string) {}

  log(msg?: any, ...data: any[])   { this._log(console.log,   msg, data) }
  info(msg?: any, ...data: any[])  { this._log(console.info,  msg, data) }
  warn(msg?: any, ...data: any[])  { this._log(console.warn,  msg, data) }
  error(msg?: any, ...data: any[]) { this._log(console.error, msg, data) }

  private _log(func: (msg?: any, ...data: any[]) => void, msg?: any, data?: any[]) {
    if (isDevMode()) {
      func(`%c${new Date().toLocaleString('da-DK')}: %c${this._class}: %c${msg}`, 'color:blue', 'color:red', 'color:black', ...data)
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  getLogger(_class: string): Logger {
    return new Logger(_class)
  }
}
