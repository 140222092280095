import { Component, Input,} from '@angular/core';
import { WaterPlant } from '../../elements/waterplant';
import { CardComponent } from '../card-component';



@Component({
  selector: 'app-waterplant',
  templateUrl: './waterplant.component.html',
  styleUrls: ['./waterplant.component.scss']
})
export class WaterPlantComponent implements CardComponent {
  @Input('data') data !: WaterPlant;

  //title = this.data["category"]
  constructor(){
  }
  link = ""

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["01id", ["Nummer", ""]],
    ["02name", ["Navn", ""]],
    ["03main_type", ["Hovedtype", ""]],
    ["04sub_type", ["Undertype", ""]],
    ["05owner", ["Ejer", ""]],
    ["06water_type", ["Vandtype", ""]],
    ["09permit_period", ["Seneste registrerede tilladelse", ""]],
    ["10permit_amount", ["Tilladt mængde", "m3/år"]],
    ["13latest_amount", ["Seneste mængde", ""]],
  ])

  isMapped(property: string, property2): boolean {
    if (property.includes("web")){
      //console.log("Link found", property2)
      this.link = property2
      return false
    }
    else{
      return this.map.has(property)
    }
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }

  hasLink(): boolean {
    //console.log("Checking link: ", this.link, !(this.link == ""))
    return !(this.link == "")
  }

  getlink(): string {
    //console.log("Getting link: ", this.map.get["link"])
    return this.link
  }

  containsThis(property1, property2){
    return ((property1.includes(property2)))
  }

  linkClicked(){
    // console.log("Link clicked : " + this.link),
    window.open(this.link)
  }
}
