import { Component, OnInit } from '@angular/core';
import { transition, trigger, animate, style } from '@angular/animations';
import * as echarts from 'echarts';
import { Logger, LoggingService } from "src/app/services/logging/logging.service";
import { DataService } from 'src/app/services/data/data.service';
import { CurrentDomainService } from 'src/app/services/current-domain.service';
import { DomainDTO } from "src/app/models/dtos/domain-dto";
import { SourceDTO } from 'src/app/models/dtos/source-dto';
import { TranslationDTO } from 'src/app/models/dtos/translation-dto';
import { Translation } from 'src/app/models/object-classes/Translation';
import { Source } from 'src/app/models/object-classes/Source';
import { Point, PointSeries, PointGroup } from 'src/app/models/object-classes/Point';
import { map, mergeMap, tap } from 'rxjs/operators';
import { merge, forkJoin, Observer, Subscription } from 'rxjs';
import { point, tooltip } from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { SeriesManager } from 'src/app/models/series-manager';
import { SeriesUtils } from 'src/app/utils/SeriesUtils';
import { SerieService } from 'src/app/services/serie/serie.service';
import { WidgetService } from 'src/app/services/widget/widget.service';
import { Observable, Subject, of } from 'rxjs';
import { inject } from '@angular/core/testing';


// This class handles everything concerning the graph module in the bottom of the screen, starting with the button to open the graph module
// The graph module is opened by clicking the button "Vis grafer", and closed by clicking the button "Luk grafer" on the graph module itself
// The graph module is closed by default
// The graph module contains a graph container, which contains the graphs themselves
// The "Vis grafer" button and the graph container is defined mainly in the html file of the graph module

// It also handles the help button and textbox, which is the same module, but with help text instead of graphs. This is mainly handled in the html file and by the varible "help_open"


interface EnvData {
  wind_dir: number
  wind_speed: number
  temp: number
  co2: number
}




@Component({
  selector: 'app-main-graph',
  templateUrl: './main-graph.component.html',
  styleUrls: ['./main-graph.component.scss'],
  animations: [
    trigger('showGraphContainer', [           // Animation for the graph container
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('400ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class MainGraphComponent {

  private _logger: Logger

  DATA_INTERVAL_IN_HOURS: number = 744           // The interval of data to be shown in the graph, in hours (744 hours = 31 days) 

  no_domain_selected: boolean = true         // Variable to show the "Help" button when no domain is selected
  domain_selected: boolean = false        // Variable to show the "Vis grafer" button when a domain is selected
  help_open: boolean = false        // Variable to open the graph module with help text, when no domain is selected - content defined in the html file
  empty_graph_open: boolean = false        // Variable to open the empty graph module - content defined in the html file
  graph_open: boolean = false        // Variable to open the graph module
  country_graph_open: boolean = false        // Variable to open the graph module when a country is selected


  graph_container_height: number                 // Variable to set the height of the graph container, set in the method openGraph()
  graph_container_bottom: number                 // Variable to set the bottom of the graph container, set in the method openGraph() 
  // when graph_container_bottom is 0 it is shown, when it is -1 * graph_container_height it is hidden "bellow the screen"

  private _domain: DomainDTO | undefined = undefined      // The current domain - undefined if no domain is selected
  private domain_type: string = ""                            // The type of the current domain (dar_address_id, municipality_code, country_code)
  private domain_code: string = ""                            // The code of the current domain (DK, 607 etc.)

  data_sources: Source[] = []
  translations: Translation[] = []
  translations_done: number = 0
  data_set: Graphdata 

  
  

  //selectedData: BehaviorSubject<EnvData> = new BehaviorSubject(undefined)
  //private _serieManager: SeriesManager

  // Variables shown in the widgets on the left side of the graph module
  public _co2: number = null
  private _co2_obs: Subscription
  public _temperature: number = null
  private _temperature_obs: Subscription
  public _wind_speed: number = null
  private _wind_speed_obs: Subscription
  public _wind_dir: number = null
  private _wind_dir_obs: Subscription
  public _wind_direction_transformation: string = "rotate(0deg)"

  constructor(
    private _dataService: DataService,
    private _widgetService: WidgetService,
    private _loggingService: LoggingService,
    private _serieService: SerieService,
    private _currentDomainService: CurrentDomainService,
  ) {
    this._logger = _loggingService.getLogger(MainGraphComponent.name)
    //this._serieManager = new SeriesManager(_serieService, _dataService, _loggingService)
    this._currentDomainService.domainChange.subscribe((value) => {
      this.update_domain()
    })
    this.data_set = new Graphdata(this._widgetService)
  }

  openGraph() {
    if (this.no_domain_selected) {
      this.help_open = true;
      this.graph_container_height = 350
    } else if ((this._currentDomainService.isSet()) && (this.data_sources.length > 0)) {
      this.graph_open = true;
      this.graph_container_height = 400
    } else {
      this.empty_graph_open = true;
      this.graph_container_height = 420
    }
    this.graph_container_bottom = 0
  }

  closeGraph() {
    this.graph_open = false
    this.empty_graph_open = false
    this.help_open = false
  }

  update_domain() {
    this.no_domain_selected = false
    this.domain_selected = true
    this._domain = this._currentDomainService.get()
    this.closeGraph()
    this.set_data_sources()
  }

  get_wind_direction_transformation() {
    return 'rotate(' + this._wind_dir + 'deg)'
  }

  clear_data_sources(): void {
    this.data_sources = []
    this._widgetService.clearAll()
  }

  set_data_sources(): void {
    this.clear_data_sources()
    this.data_set = new Graphdata(this._widgetService)
    if (this._domain.group == "kommuner") {
      this.domain_type = "municipality_code"
      this.domain_code = this._domain.meta.code
    } else if (this._domain.group == "lande") {
      this.domain_type = "country_code"
      this.domain_code = this._domain.meta.code
    }
    this._dataService.getSources(
      this.domain_type,
      this.domain_code
    ).pipe(map((source: SourceDTO[]) => {
      return source.map((source: SourceDTO) => {
        var local_source = new Source(this.domain_type, this.domain_code, source.category, source.data_source, source.process_type)
        this.data_sources.push(local_source)
        return this._dataService.getTranslation(
          "dk",
          local_source.getCategory(),
          local_source.getData_source(),
          local_source.getProcess_type()
        ).pipe(map((translation: TranslationDTO[]) => {
          return this._dataService.getData(source, this.DATA_INTERVAL_IN_HOURS).pipe(map(data => {
            if (translation.length == 0) return local_source
            else if (translation[0].show){
              let mapped: Point[] = data.map(v => new Point(new Date(v.timestamp), v.value))
              var pointseries = new PointSeries(translation[0].data_type, translation[0].data_unit)
              pointseries.setNumerator(translation[0].numerator)
              pointseries.addMeta([translation[0].data_type])
              translation[0].comments? pointseries.addMeta(translation[0].comments): null
              pointseries.addPoints(mapped.reverse())
              this.data_set.addPointSeries(pointseries)
              this.data_set.setGraphoption(this._domain.name)
              this.data_set.setEchartsoption()
              if (local_source.getCategory() == "wind_direction") { this._widgetService.setWindDirection(pointseries) }
              else if (local_source.getCategory() == "wind_speed") { this._widgetService.setWindSpeed(pointseries) }
              else if (local_source.getCategory() == "temperature") { this._widgetService.setTemperature(pointseries) }
              else if (local_source.getCategory() == "co2_per_production") { this._widgetService.setCO2(pointseries) }
              return local_source
            }
            else { 
              let mapped: Point[] = data.map(v => new Point(new Date(v.timestamp), v.value))
              var pointseries = new PointSeries(translation[0].data_type, translation[0].data_unit)
              pointseries.setNumerator(translation[0].numerator)
              pointseries.addMeta([translation[0].data_type])
              translation[0].comments? pointseries.addMeta(translation[0].comments): null
              pointseries.addPoints(mapped.reverse())
              if (local_source.getCategory() == "wind_direction") { this._widgetService.setWindDirection(pointseries) }
              else if (local_source.getCategory() == "wind_speed") { this._widgetService.setWindSpeed(pointseries) }
              else if (local_source.getCategory() == "temperature") { this._widgetService.setTemperature(pointseries) }
              else if (local_source.getCategory() == "co2_per_production") { this._widgetService.setCO2(pointseries) }
              return local_source
            }
          }));
        }))
      });
    })
    ).subscribe(sources => {
      forkJoin(sources).subscribe(sources => {
        forkJoin(sources).subscribe(sources => {
          this.data_set.setGraphoption(this._domain.name)
          this.data_set.setEchartsoption()
        })
      })
    });
    this._co2_obs = this._widgetService.co2$.subscribe((value) => {
      this._co2 = value
    })
    this._temperature_obs = this._widgetService.temperature$.subscribe((value) => {
      this._temperature = value
    })
    this._wind_speed_obs = this._widgetService.windspeed$.subscribe((value) => {
      this._wind_speed = value
    })
    this._wind_dir_obs = this._widgetService.winddirection$.subscribe((value) => {
      this._wind_dir = value
      this._wind_direction_transformation = "rotate(" + value + "deg)"    
    })
  }
  /*
  onTimeChanged(event: any, done: boolean): void {
    if (done) {
        event.source._elementRef.nativeElement.blur()
    }

    let ed: EnvData = {
        wind_dir:   this._serieManager.getValue(SeriesUtils.WEATHER_KEY_WIND_DIR, event.value),
        wind_speed: this._serieManager.getValue(SeriesUtils.WEATHER_KEY_WIND_SPEED, event.value),
        temp:       this._serieManager.getValue(SeriesUtils.WEATHER_KEY_WIND_TEMP, event.value),
        co2:        this._widgetService.getCO2()
    }

    this.selectedData.next(ed)
  }*/
}




class Graphdata {
  private _pointgroup: PointGroup
  private _options: echarts.EChartsOption
  private _echartsoption: echarts.EChartsOption
  private _graphoption: GraphOptions
  private _numerator: number = 1
  constructor(
    private _widgetService: WidgetService
    ) {
    this._pointgroup = new PointGroup
  }
  getPointgroup() { return this._pointgroup }
  getGraphoption() { return this._graphoption }
  addPointSeries(series: PointSeries) { this._pointgroup.addPointSeries(series) }
  setGraphoption(title: string) { this._graphoption = new GraphOptions(title, this._pointgroup, this._widgetService) }
  setEchartsoption() { this._echartsoption = this._graphoption.getGraphOptions() }
  getEchartsoption() { return this._echartsoption}
}

class GraphOptions {
  private _options: echarts.EChartsOption
  private _visual: any
  private _visuals: any[]
  constructor(
    private _title: string,
    private _data: PointGroup,
    private _widgetService: WidgetService
  ) {
    
    this._options = {
      title: { text: _title, left: "center", top: "auto", textStyle: {fontSize: 16}, }, 
      tooltip: {  
        trigger: 'axis', 
        formatter: function (params) { 
          var res = params[0].name;
          for (var i = 0, l = params.length; i < l; i++) { 
            _widgetService.updateTimestamp(params[0].data[0])
            var valueString = params[i].value.toString()
            var localdata : String = valueString.split(",")
            res += params[i].marker + params[i].seriesName + ' : ' + localdata[1] + " " +  _data.getUnitByName(params[i].seriesName) + '<br/>'
          } 
          return res;
        }, 
        showContent: true },
      legend: { orient: 'vertical', right: 10, top: 'center', tooltip: { show: true} },
      toolbox: { show: true, feature: { dataZoom: { yAxisIndex: 'none', xAxisIndex: 'all' }, dataView: { readOnly: false }, magicType: { type: ['line', 'bar', 'stack'] }, restore: {}, saveAsImage: {} } },
      xAxis: { type: 'time', axisLabel: { overflow: 'truncate' } },      
      axisTick: { alignWithLabel: true },
      yAxis: { type: 'value', axisLabel: { formatter: '{value}' } },
      series: _data.buildSeriesGroup(),
    }
  }

  getGraphOptions(): echarts.EChartsOption {
    return this._options
  }
}

