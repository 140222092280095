import { Component, Injectable, Type } from '@angular/core';
import { PowerplantComponent } from 'src/app/models/cards/powerplant/powerplant.component';
import { WindturbineComponent } from 'src/app/models/cards/windturbine/windturbine.component';
import { PowerToXComponent } from 'src/app/models/cards/powertox/powertox.component';
import { LivingLabComponent } from 'src/app/models/cards/livinglab/livinglab.component';
import { DHCoverageComponent } from 'src/app/models/cards/dhcoverage/dhcoverage.component';
import { GasCoverageComponent } from 'src/app/models/cards/gascoverage/gascoverage.component';
import { WWCoverageComponent } from 'src/app/models/cards/wwcoverage/wwcoverage.component';
import { WaterPlantComponent } from 'src/app/models/cards/waterplant/waterplant.component';
import { WaterCoverageComponent } from 'src/app/models/cards/watercoverage/watercoverage.component';
import { PublicBuildingComponent } from 'src/app/models/cards/publicbuilding/publicbuilding.component';
import { WindTurbine } from 'src/app/models/elements/windturbine';
import { LivingLab } from 'src/app/models/elements/livinglab';
import { PowerPlant } from 'src/app/models/elements/powerplant';
import { PowerToX } from 'src/app/models/elements/powertox';
import { PublicBuilding } from 'src/app/models/elements/publicbuilding';
import { DHCoverage } from 'src/app/models/elements/dhcoverage';
import { GasCoverage } from 'src/app/models/elements/gascoverage';
import { WWCoverage } from 'src/app/models/elements/wwcoverage';
import { WaterPlant } from 'src/app/models/elements/waterplant';
import { WaterCoverage } from 'src/app/models/elements/watercoverage';
import { CardComponent } from 'src/app/models/cards/card-component';
import { UnknownComponent } from 'src/app/models/cards/unknown/unknown.component';
import { Base } from 'src/app/models/elements/base';
import { Unknown } from 'src/app/models/elements/unknown';

type CategoryDetails = {
  class: Type<Base>,
  icon: string
}

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  private WINDTURBINE_CATEGORY_NAME: string = 'wind_turbine';
  private POWERPLANT_CATEGORY_NAME: string = 'plant';
  private POWERTOX_CATEGORY_NAME: string = 'power_to_x';
  private LIVING_LAB_CATEGORY_NAME: string = 'living_lab';
  private PUBLIC_BUILDING_CATEGORY_NAME: string = 'public_building';
  private DH_COVERAGE_CATEGORY_NAME: string = 'dh_coverage';
  private GAS_COVERAGE_CATEGORY_NAME: string = 'gas_coverage';
  private WW_COVERAGE_CATEGORY_NAME: string = 'ww_coverage';
  private WATER_PLANT_CATEGORY_NAME: string = 'water_plant';
  private WATER_COVERAGE_CATEGORY_NAME: string = 'water_coverage';

  constructor() { }

  private classMap: Map<string, CategoryDetails> = new Map<string, CategoryDetails>([
    [this.WINDTURBINE_CATEGORY_NAME, { class: WindTurbine, icon: 'api/v1/asset/627bd5d3e0f78ce9d3000374' }],
    [this.POWERPLANT_CATEGORY_NAME, { class: PowerPlant, icon: 'api/v1/asset/627bd5d3e0f78ce9d3000365' }],
    [this.POWERTOX_CATEGORY_NAME, { class: PowerToX, icon: 'api/v1/asset/627bd5d3e0f78ce9d3000365' }],
    [this.LIVING_LAB_CATEGORY_NAME, { class: LivingLab, icon: 'api/v1/asset/640857daa2cc0ed5aecb0e67' }],
    [this.PUBLIC_BUILDING_CATEGORY_NAME, { class: PublicBuilding, icon: 'api/v1/asset/6453955dc84c5c7a5f91a3f2' }],
    [this.DH_COVERAGE_CATEGORY_NAME, { class: DHCoverage, icon:'api/v1/asset/6400799ee36bdc094ba2d25e' }],
    [this.GAS_COVERAGE_CATEGORY_NAME, { class: GasCoverage, icon:'api/v1/asset/6400799ee36bdc094ba2d25e' }],
    [this.WW_COVERAGE_CATEGORY_NAME, { class: WWCoverage, icon:'api/v1/asset/6400799ee36bdc094ba2d25e' }],
    [this.WATER_PLANT_CATEGORY_NAME, { class: WaterPlant, icon:'api/v1/asset/6400799ee36bdc094ba2d25e' }],
    [this.WATER_COVERAGE_CATEGORY_NAME, { class: WaterCoverage, icon:'api/v1/asset/6400799ee36bdc094ba2d25e' }]
  ])

  private componentMap: Map<string, Type<CardComponent>> = new Map<string, Type<CardComponent>>([
    [WindTurbine.name, WindturbineComponent],
    [PowerPlant.name, PowerplantComponent],
    [PowerToX.name, PowerToXComponent],
    [LivingLab.name, LivingLabComponent],
    [PublicBuilding.name, PublicBuildingComponent],
    [DHCoverage.name, DHCoverageComponent],
    [GasCoverage.name, GasCoverageComponent],
    [WWCoverage.name, WWCoverageComponent],
    [WaterPlant.name, WaterPlantComponent],
    [WaterCoverage.name, WaterCoverageComponent]

  ])

  getCategoryComponent(category: string): Type<CardComponent> {
    return category ? this.componentMap.get(category) || UnknownComponent : UnknownComponent;
  }

  getCategoryClass(category: string): Type<Base> {
    // console.log(this.classMap.get(category))
    return category ? this.classMap.get(category).class || Unknown : Unknown;
  }

  getCategoryIcon(category: string): string {
    // todo: find defaultIconLocation
    return category ? this.classMap.get(category).icon || 'defaultIconLocation' : 'defaultIconLocation';
  }
}
