export class JSONUtils {

    public static sortObject(obj: { [key: string]: any }): { [key: string]: any } {
        // Step 1: Get the keys of the object as an array
        const keys = Object.keys(obj);

        // Step 2: Sort the keys
        keys.sort();

        // Step 3: Create a new object with the sorted keys
        const sortedObject: { [key: string]: any } = {};
        keys.forEach((key) => {
          sortedObject[key] = obj[key];
        });

        return sortedObject;
      }
}
