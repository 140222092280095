export class  Domain {
    constructor(
        private domain_type: string,
        private domain_code: string,
    ) { }
    getDomain_type() { return this.domain_type }
    getDomain_code() { return this.domain_code }
}

export class Source {
    constructor(
        private domain_type: string,
        private domain_code: string,
        private category: string,
        private data_source: string,
        private process_type: string,
    ) { }
    getCategory() { return this.category }
    getDomain() { 
        var result = new Domain(this.domain_type, this.domain_code)
        return result
    }
    getData_source() { return this.data_source }
    getProcess_type() { return this.process_type }
}

