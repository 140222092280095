export abstract class Base {
    
    private _properties: Map<string, string | number>;

    constructor(properties: Map<string, string | number>) {
        this._properties = properties
    }

    public getProperties(): Map<string, string | number> {
        return this._properties;
    };

    public getType(): string {        
        return this._properties && this._properties.has('category') ? String(this._properties.get('category')) : null;
    }
}