import { Injectable, Type } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MappingService } from "../services/mapping/mapping.service";
import { ElementDTO } from "./dtos/element-dto";
import { Base } from "./elements/base";

@Injectable({
    providedIn: 'root'
})
export class InfoModalManager {

    private _isMoreInfoOpen: boolean = false

    public get isMoreInfoOpen() { return this._isMoreInfoOpen }

    private selectedElement: Base;

    private matBottomSheetRef: MatBottomSheetRef; 

    constructor(private mappingService: MappingService) {
    }

    openMoreInfoModal(selected: ElementDTO): void {
        if (selected) {            
            this.setSelectedElement(selected);
        } 
        this._isMoreInfoOpen = true
        if (this.matBottomSheetRef) this.matBottomSheetRef.dismiss()

    }

    closeMoreInfoModal(): void {
        this._isMoreInfoOpen = false
    }

    setSelectedElement(selected: ElementDTO): void {
        const type: Type<Base> = this.mappingService.getCategoryClass(selected.meta['category']);
        this.selectedElement = new type(selected.meta);
    }

    getSelectedElement(): Base {
        return this.selectedElement;
    }

    setMatBottomSheetRef(matBottomSheetRef: MatBottomSheetRef): void {
        this.matBottomSheetRef = matBottomSheetRef;
    }

}