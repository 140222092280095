<!-- This class handles everything concerning the graph module in the bottom of the screen (including the help function), starting with the button to open the graph module -->  

<!------- The help content ------->

<!-- The help button, shown when no domain is selected -->
<div *ngIf="no_domain_selected">
  <button class="map-help-button" mat-raised-button (click)="openGraph()">Hjælp</button>
</div>

<!-- The help module, to be shown, when the help button is clicked, to guide the user to select a domain -->
<div *ngIf="help_open" [@showGraphContainer] id="helpContainer" [style.bottom.px]="graph_container_bottom" [style.height.px]="graph_container_height">
  <div class="help-text">  
    <h1>Hvis du vælger et område, finder vi nogle spændende data om området til dig. </h1>
    <div>I feltet "Vælg område" øverst på skærmen kan du søge og enten vælge en kommune eller vælge Danmark, som viser vores landsdækkende lag.
      <br>Når du har valgt et område, kan du efterfølgende vælge et visuelt lag i menuen til venstre, hvor kategorierne kan foldes ud, så du kan vælge mere specifik data.
      <br>Vær opmærksom på at energikortet.dk stadig er under udvikling, så det er muligt at der er områder, hvor der i visse kategorier ikke er data tilgængeligt.
      <br>Hvis du vælger en kommune, vil "Hjælp" knappen blive skiftet ud med en "Vis grafer" knap, som åbner et grafmodul på siden, der er specifikt for området.
      <br>Hvis du vælger Danmark, har vi desværre endnu ikke grafer tilgængelige, men vi arbejder på sagen, så vi kan vise endnu mere spændende data.
      <br>Du kan naturligvis skifte område undervejs, ved at foretage en ny søgning i feltet "Vælg område". 
    </div>  
    <h3>Vi håber du vil tage godt imod energikortet.dk og vi vil meget gerne høre din mening om siden, så vi kan gøre den endnu bedre.</h3>
  </div>
    
    <!-- The button to close the help module -->
    <div> <button class="close-graph-button" mat-raised-button (click)="closeGraph()">Luk</button> </div>
</div>


<!------- The graph content ------->

<!-- The button to open the graph module, shown when a domain is selected -->

<div *ngIf="domain_selected">
  <button class="show-graph-button" mat-raised-button (click)="openGraph()">Vis grafer</button>
</div>

<!-- The icon container (left side of the module) -->

<div *ngIf="graph_open" [@showGraphContainer] id="iconContainer" [style.bottom.px]="graph_container_bottom" [style.height.px]="graph_container_height">
   <!--The icons on the side -->
  <div class="graph_icons">
    <span>Vindretning</span>
    <mat-icon aria-hidden="false" aria-label="Wind direction arrow" class="wind-arrow"
        [style.transform]="_wind_direction_transformation">navigation</mat-icon>
  </div>
  <div class="graph_icons">
    <span>Vindstyrke</span>
      <span class="value">{{ _wind_speed }}</span>
      <span class="value-unit">m/s</span>
  </div>
  <div class="graph_icons">
    <span>Temperatur</span>
    <span class="value">{{ _temperature }}</span>
    <span class="value-unit">°C</span>
  </div>
  <div class="graph_icons">
    <span>CO2</span>
    <span class="value">{{ _co2 }}</span> 
    <span class="value-unit">g/kWt</span>
  </div>
</div>


<!-- The graph container -->

<div *ngIf="graph_open" [@showGraphContainer] id="graphContainer" [style.bottom.px]="graph_container_bottom" [style.height.px]="graph_container_height">
 

  <!-- The graph module itself -->

  <div data_set. echarts [options]="data_set.getEchartsoption()" class="chart"></div>
  
  <!-- The button to close the graph -->

  <div> <button class="close-graph-button" mat-raised-button (click)="closeGraph()">Luk</button> </div>
</div>


<!------- The empty graph content ------->

<!-- The empty graph module, to be shown, when the graph button is clicked, but there is no available data, to guide the user to select a (different) domain -->
<div *ngIf="empty_graph_open" [@showGraphContainer] id="helpContainer" [style.bottom.px]="graph_container_bottom" [style.height.px]="graph_container_height">
  <div class="help-text">  
    <h1>Ups!</h1>
    <h3>Det ser ud til, at vi ikke har graf data tilgængeligt for det valgte område lige nu.</h3>
    <br>I feltet "Vælg område" øverst på skærmen kan du søge og enten vælge en kommune eller vælge Danmark, som viser vores landsdækkende lag.
    <br>Når du har valgt et område, kan du efterfølgende vælge et visuelt lag i menuen til venstre, hvor kategorierne kan foldes ud, så du kan vælge mere specifik data.
    <br>Vær opmærksom på at energikortet.dk stadig er under udvikling, så det er muligt at der er områder, hvor der i visse kategorier ikke er data tilgængeligt.
    <br>Hvis du vælger en kommune, vil "Hjælp" knappen blive skiftet ud med en "Vis grafer" knap, som åbner et grafmodul på siden, der er specifikt for området.
    <br>Hvis du vælger Danmark, har vi desværre endnu ikke grafer tilgængelige, men vi arbejder på sagen, så vi kan vise endnu mere spændende data.
    <br>Du kan naturligvis skifte område undervejs, ved at foretage en ny søgning i feltet "Vælg område". 
     
    <h3><br>Vi håber du vil tage godt imod energikortet.dk og vi vil meget gerne høre din mening om siden, så vi kan gøre den endnu bedre.</h3>
  </div>
    
    <!-- The button to close the empty graph module -->
    <div> <button class="close-graph-button" mat-raised-button (click)="closeGraph()">Luk</button> </div>
</div>

