import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BehaviorSubject } from 'rxjs';
import { Serie } from 'src/app/models/serie';
import { Options, SerieWrapper } from '../cdk-plot/cdk-plot.component';

interface Legenditem {
    options: Options,
    serie: Serie
}

// class DelayedBehaviorSubject<T> extends BehaviorSubject<T> {
//     private timeout: any

//     constructor(v: T) {
//         super(v)
//     }

//     next(v: T) {
//         clearTimeout(this.timeout)

//         this.timeout = setTimeout(() => {
//             super.next(v)
//         }, 5000)
//     }
// }

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent {

    public series: BehaviorSubject<SerieWrapper[]> = new BehaviorSubject([])
    public legend: BehaviorSubject<Legenditem[]> = new BehaviorSubject([])

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: BehaviorSubject<Serie[]>,
        private _matBottomSheetRef: MatBottomSheetRef
    ) {
        data.subscribe(series => {
            let items = this.legend.value.filter(prevItem => series.find(serie => serie.key === prevItem.serie.key))

            series.forEach(serie => {
                const options = {
                    name: serie.meta?.name || '',
                    enabled: serie.meta?.toggled || false,
                    color: serie.meta?.color || '#000',
                    unit: serie.meta?.unit || '?'
                }

                let item: Legenditem = items.find(item => item.serie.key === serie.key)

                if (item) {
                    item.serie = serie
                } else {
                    item = {
                        options: options,
                        serie: serie
                    }
                    items.push(item)
                }
            })

            this.legend.next(items)
            this.updateSeries()
        })
    }

    closeBottomSheet(): void {
        this._matBottomSheetRef.dismiss()
    }

    toggleChange(): void {
        this.updateSeries()
    }

    private updateSeries(): void {
        let series: SerieWrapper[] = []

        this.legend.value.forEach(item => {
            if (item.options.enabled) {

                let sw: SerieWrapper = {
                    serie: item.serie,
                    options: item.options
                }

                series.push(sw)
            }
        })

        this.series.next(series)
    }
}
