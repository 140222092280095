import { Component, Input } from '@angular/core';
import { PowerToX } from '../../elements/powertox';
import { CardComponent } from '../card-component';

@Component({
  selector: 'app-powertox',
  templateUrl: './powertox.component.html',
  styleUrls: ['./powertox.component.scss']
})
export class PowerToXComponent implements CardComponent {
  @Input('data') data !: PowerToX;

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["name", ["Navn", ""]],
    ["type", ["Type", ""]],
    ["info", ["Info", ""]],
    ["link", ["Link", ""]],
  ])

  isMapped(property: string): boolean {
    return this.map.has(property)
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }
}