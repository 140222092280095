import { Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild } from '@angular/core';
import { MappingService } from 'src/app/services/mapping/mapping.service';
import { CardComponent } from '../cards/card-component';
import { CardDirective } from '../cards/card-directive';
import { Base } from '../elements/base';

@Component({
  selector: 'modal-content-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  
  private _data: Base | undefined

  @Input('data') set data(value: Base | undefined) {
    this._data = value
    this.loadTemplate()
  }

  @ViewChild(CardDirective, {static: true}) cardhost !: CardDirective
  
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private mappingService: MappingService
  ) { }
  
  ngOnInit(): void {
    this.loadTemplate()
  }
  
  loadTemplate() {    
    const type: Type<CardComponent> = this.mappingService.getCategoryComponent(this._data?.constructor.name)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);

    const viewContainerRef = this.cardhost.viewContainerRef   
    viewContainerRef.clear()

    const componentRef = viewContainerRef.createComponent<CardComponent>(componentFactory)
    componentRef.instance.data = this._data
  }
}
