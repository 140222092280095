import { LatLng, Layer, Polyline } from "leaflet";
import { ElementBuilder } from "../builder";
import { ElementDTO } from "../dtos/element-dto";

export class PolylineBuilder implements ElementBuilder {

    build(element: ElementDTO): Layer {

        const coords: LatLng[] = element.geo['coords'].map<L.LatLng>(point => {
            return new LatLng(point[1], point[0])
        })

        let polyline = new Polyline(coords, {
            weight: 1,
            color: 'black'
        })

        return polyline
    }

}