import { Component, Input,} from '@angular/core';
import { GasCoverage } from '../../elements/gascoverage';
import { CardComponent } from '../card-component';


@Component({
  selector: 'app-gascoverage',
  templateUrl: './gascoverage.component.html',
  styleUrls: ['./gascoverage.component.scss']
})
export class GasCoverageComponent implements CardComponent {
  @Input('data') data !: GasCoverage; 
  
  //title = this.data["category"]
  constructor(){
    //console.log(this.data)
  }

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["supplier", ["Leverandør", ""]],
    ["start", ["Påbegyndelse", ""]],
    ["completion", ["Færdiggørelse", ""]],
    ["focus", ["Fokus", ""]],
    ["plan_name", ["Plannavn", ""]],
    ["part_name", ["Delnavn", ""]],
    ["plan_link", ["Planlink", ""]],    
    ["description", ["Beskrivelse", ""]],
  ])
  
  isMapped(property: string): boolean {
    return this.map.has(property)
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }
}