import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ElementDTO } from 'src/app/models/dtos/element-dto';
import { LayerDTO } from 'src/app/models/dtos/layer-dto';
import { DomainDTO } from 'src/app/models/dtos/domain-dto';

@Injectable({
    providedIn: 'root'
})
export class ElementService {

    constructor(private http: HttpClient) { }

    OLDgetElements(layer: LayerDTO, domain: DomainDTO): Observable<ElementDTO[]> {         //getElements
        return this.http.get<ElementDTO[]>(`/api/v1/element/${layer.id}/${domain.id}`)
    }

    getElements(layer: LayerDTO, domain: DomainDTO): Observable<ElementDTO[]> {         //getDomainByCode
        var options = layer && domain ? { params: new HttpParams().set('layer', layer.id).set('domain', domain.id),  } : {};
    
        return this.http.get<ElementDTO[]>(`/api/v1/element`, options)
    }
}
