import { Point } from "./point"
import { SerieDTO } from "./dtos/serie-dto"
import { SerieMeta } from "./serie-meta"

/**
 * Represents a single dataserie
 */
export class Serie {

    private _first: number | undefined
    private _last:  number | undefined
    private _min:   number | undefined
    private _max:   number | undefined

    private _points: Map<number, Point> = new Map()

    constructor (private _key: string, private _meta: SerieMeta) {}

    public hasData(): boolean {
        return this._points.size > 0
    }

    public getValue(timestamp: number): number | undefined {
        return this._points.get(timestamp)?.value
    }

    public update(points: Point[]) {
        this._points = new Map(points.map(p => [p.timestamp, p]))

        let pa = Array.from(this._points.values())

        this._first = (pa.length > 0 ? pa[0].timestamp : 0)
        this._last = (pa.length > 0 ? pa[pa.length-1].timestamp : 0)

        let values = pa.map(p => p.value)

        this._min = (values.length > 0) ? Math.min(...values) : 0
        this._max = (values.length > 0) ? Math.max(...values) : 0
    }

    get first() { return this._first }
    get last()  { return this._last }

    get min() { return this._min }
    get max() { return this._max }

    get points() { return Array.from(this._points.values()) }

    get key() { return this._key }
    get meta() { return this._meta }
}
