
import * as L from 'leaflet';
import { MappingService } from "src/app/services/mapping/mapping.service";
import { ElementBuilder } from "../builder";
import { ElementDTO } from "../dtos/element-dto";
import { InfoModalManager } from "../info-modal-manager";
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { title } from 'process';

export class MinimarkerBuilder implements ElementBuilder {

    constructor(
        private _infoModalManager: InfoModalManager,
        private mappingService: MappingService
    ) { }

    build(element: ElementDTO): L.Layer {
        let metadata = element.meta
        let coords = element.geo['coords']
        let minimarker
        let color = metadata['color']

        let fillColor = color
        if(metadata['category'] == 'water_plant' && (metadata['08permit_end'] < ((new Date()).getFullYear() -1)))
            color = '#d3d3d3'   //gray outline if expired

        switch(metadata['type']){
            case 'A':
                minimarker = new L.Circle([coords[0], coords[1]], {
                    color: color,
                    fillColor: fillColor,
                    fillOpacity: 0.4,
                    weight: 8,
                    radius: 10
                })
                //setPopupContent(metadata['name'] + "\n" + metadata['class'])
                break
            case 'B':
                minimarker = new L.Marker([coords[0], coords[1]])
                break
            default:
                break
        }
        minimarker.bindTooltip("<center><b>" + metadata['01id'] + "</b><br />" + metadata['02name'] + "<br /><b><i>" + metadata['03main_type'] + "</i></b></center>")


        let html = `
            <div style="border-radius: 25px; padding: 0px; text-align: center;">
                <img src="${this.mappingService.getCategoryIcon(metadata['category'])}" width="50" style="pointer-events: none;"/>
            </div>
        `


        let icon = new L.DivIcon({
            className: "leaflet-data-marker",
            html: html,
            iconSize: [50, 50],
            iconAnchor: [35, 55]
        })


        //let minimarker = new Marker([coords[0], coords[1]], { icon: icon })

        minimarker.on('click', e => {
            this._infoModalManager.openMoreInfoModal(element)
        })

        // console.log(minimarker)
        return minimarker
    }

}
