import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { select, scaleLinear, scaleTime, axisBottom, axisLeft, line, AxisScale } from 'd3';
import { ResizeObserver } from 'resize-observer';
import { Serie } from 'src/app/models/serie';

export interface Options {
    name: string
    enabled: boolean
    color: string
    unit: string
}

export interface SerieWrapper {
    serie: Serie,
    options: Options
}

class DataSet {
    constructor(
        private _xmin: number,
        private _xmax: number,
        private _ymin: number,
        private _ymax: number,
        private _series: SerieWrapper[]) { }

    public get xmin() { return this._xmin }
    public get xmax() { return this._xmax }
    public get ymin() { return this._ymin }
    public get ymax() { return this._ymax }
    public get series() { return this._series }
}

const MARGINS = {
    top: 15,
    bottom: 50,
    left: 65,
    right: 5
}

@Component({
    selector: 'cdk-plot',
    templateUrl: './cdk-plot.component.html',
    styleUrls: ['./cdk-plot.component.scss']
})
export class CdkPlotComponent implements AfterViewInit {

    private localeDef = {
        "dateTime": "%A, der %e. %B %Y, %X",
        "date": "%b %d",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
        "shortDays": ["Søn", "Man ", "Tir", "Ons", "Tor", "Fre", "Lør"],
        "months": ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
        "shortMonths": ["Jan", "Feb", "Mrz", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
    }

    private timeFormatter = d3.timeFormatLocale(this.localeDef)
    private timeFormat = this.timeFormatter.format(this.localeDef.date)

    private _element: Element | undefined = undefined
    @ViewChild('container') private container !: ElementRef<any>

    private _data: DataSet
    @Input() set data(series: SerieWrapper[]) {

        let non_empty = series.filter(sw => sw.serie.points.length > 0)

        let xmin = non_empty.length > 0 ? Math.min(...non_empty.map(sw => sw.serie.first)) : 0
        let xmax = non_empty.length > 0 ? Math.max(...non_empty.map(sw => sw.serie.last)) : 0
        let ymin = non_empty.length > 0 ? Math.min(...non_empty.map(sw => sw.serie.min)) : 0
        let ymax = non_empty.length > 0 ? Math.max(...non_empty.map(sw => sw.serie.max)) : 0

        this._data = new DataSet(
            xmin, xmax,
            ymin, ymax,
            series
        )

        this.render()
    }

    ngAfterViewInit() {
        this._element = this.container.nativeElement

        if (this._element === undefined) return

        const ro = new ResizeObserver((entries) => entries.forEach(e => this.render()))
        ro.observe(this._element)
    }

    private render() {
        if (this._element === undefined || this._data === undefined) return

        var width = this._element.clientWidth
        var height = this._element.clientHeight

        let svg: any = select(this._element).selectAll('svg').data([null])

        // FIXME This is a hack to force-redraw the plots (they should be re-implemented)
        svg.selectAll('*').remove()

        svg = svg.enter().append('svg').merge(svg)
            .attr('width', width).attr('height', height)

        var xScale: AxisScale<number> = scaleTime()
            .domain([this._data.xmin, this._data.xmax])
            .range([0, width - MARGINS.left - MARGINS.right])
            .nice()

        var yScale: AxisScale<number> = scaleLinear()
            .domain([this._data.ymin, this._data.ymax])
            .range([height - MARGINS.top - MARGINS.bottom, 0])

        /** Draw axes */
        var x_axis = axisBottom(xScale).ticks(d3.timeHour.every(24)).tickFormat(this.timeFormat)
        var y_axis = axisLeft(yScale)

        let x = svg.selectAll('.x-axis').data([null])
        x = x.enter().append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(${MARGINS.left}, ${height - MARGINS.bottom})`)
            .merge(x)
        x.call(x_axis)
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-45)")

        let y = svg.selectAll('.y-axis').data([null])
        y = y.enter().append('g')
            .attr('class', 'y-axis')
            .attr('transform', `translate(${MARGINS.left}, ${MARGINS.top})`)
            .merge(y)
        y.call(y_axis)

        /** Draw lines */
        const lineGen = line()
            .x(d => Number(xScale(d.timestamp)))
            .y(d => Number(yScale(d.value)))
            .curve(d3.curveBasis)

        this._data.series.forEach((sw, index) => {
            let line = svg.selectAll(`.line-${index}`).data([null])
            line = line.enter().append('path').merge(line)

            line.datum(sw.serie.points)
                .attr('class', `line-${index}`)
                .attr('fill', 'none')
                .attr('stroke', sw.options.color)
                .attr('stroke-width', 2)
                .attr('transform', `translate(${MARGINS.left}, ${MARGINS.top})`)
                .attr('d', lineGen)
        })
    }
}
