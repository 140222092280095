import { Layer, Marker, DivIcon, TileLayer } from "leaflet";
import { MappingService } from "src/app/services/mapping/mapping.service";
import { ElementBuilder } from "../builder";
import { ElementDTO } from "../dtos/element-dto";
import { InfoModalManager } from "../info-modal-manager";

export class TileLayerBuilder implements ElementBuilder {

    build(element: ElementDTO): Layer {
        let url:string = element.meta['url']
        
        url = url.replace('{id}', element.id)

        return new TileLayer(url)
    }

}
