import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LayerDTO } from 'src/app/models/dtos/layer-dto';

@Injectable({
    providedIn: 'root'
})
export class LayerService {

    constructor(private http: HttpClient) { }

    getLayers(parent?: LayerDTO): Observable<LayerDTO[]> {
        return this.http.get<LayerDTO[]>(`/api/v1/layer/${parent?.id ?? ''}`)
    }
}
