import { Component, Input } from '@angular/core';
import { Base } from '../../elements/base';
import { CardComponent } from '../card-component';

@Component({
  selector: 'app-unknown',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.scss']
})
export class UnknownComponent implements CardComponent {
  @Input('data') data !: Base;
}
