<mat-toolbar id="toolbar">
    <!-- CenterDenmark logo -->
    <img id="logo" src="/assets/centerdenmark-logo.png" alt="centerdenmark-logo">

    <!--<div *ngIf="show_copylink_button; else elseBlock">Content to render when condition is true.</div>-->
    <!--<ng-template #elseBlock>Content to render when condition is false.</ng-template>-->
    <button mat-raised-button extended *ngIf="show_copylink_button" (click)="copylink_clicked()"
        style="background-color: yellow">
        <div> Copy link </div>
        <script src="app.component.ts"></script>
    </button>

    <!-- Domain selector -->
    <mat-form-field appearance="fill">
        <mat-label>Vælg område</mat-label>
        <input type="text"
            placeholder="Søg..."
            matInput
            [formControl]="domainFormControl"
            [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="domainToString" (optionSelected)="onDomainSelected($event)">
            <mat-optgroup *ngFor="let group of domainGroupsFiltered | async" [label]="group.name">
                <mat-option *ngFor="let domain of group.domains" [value]="domain">
                    {{ domain.name | titlecase }}
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
    </mat-form-field>

    <!-- Period selector 
    <mat-form-field appearance="fill" style="width: 110px;">
        <mat-label>Periode</mat-label>
        <mat-select class="period-select" [ngModel]="selectedPeriod | async"
            (ngModelChange)="selectedPeriod.next($event)">
            <mat-option *ngFor="let period of periods" [value]="period">{{ period.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="timeline">
        <label class="timeline__title">Tidslinje</label>

        <mat-slider class="timeline__slider mat-slider-sliding" [displayWith]="formatTimeLabel"
            [min]="getTimeMin() | async" [max]="getTimeMax() | async" [step]="getTimeStep() | async" [disabled]="!hasData()" thumbLabel
            (input)="onTimeChanged($event, false)" (change)="onTimeChanged($event, true)"
            (renderedTicks)='renderTicks($event)'></mat-slider>

        <div class="timeline__time"></div>
    </div>
    -->
    <!-- Various readings 
    <div class="toolbar_icons">
        <span>Vindretning</span>
        <mat-icon aria-hidden="false" aria-label="Wind direction arrow" class="wind-arrow"
            [ngStyle]="{transform: 'rotate(' + ( selectedData | async)?.wind_dir + 'deg)'}">navigation</mat-icon>
    </div>
    <div class="toolbar_icons">
        <span>Vindstyrke</span>
        <div class="value">{{ (selectedData | async)?.wind_speed | number: '1.1-1' }} <span
                class="value-unit">m/s</span></div>
    </div>
    <div class="toolbar_icons">
        <span>Temperatur</span>
        <span class="value">{{ (selectedData | async)?.temp | number: '1.1-1' }}°</span>
    </div>
    <div class="toolbar_icons">
        <span>g/kW CO2</span>
        <span class="value">{{ (selectedData | async)?.co2 | number: '1.1-1' }}</span>
    </div>
    -->
    <!-- More info button (expand?) -->
    <!-- <button mat-flat-button id="more-info" color="basic" (click)="openMoreInfoModal()" class="toolbar_icons"
        style="border-radius: 0;">
        <mat-icon aria-hidden="false" aria-label="Mere info">add_circle</mat-icon>
        <div>Mere info</div>
    </button> -->
</mat-toolbar>

<mat-sidenav-container (backdropClick)="handleBackdropClick()">
    <!-- Modal (overlay) sidenav -->
    <mat-sidenav class="sidenav__modal" #sidenav mode="side" position="end" [opened]="getInfoModalOpen()">
        <div style="text-align: right">
            <button mat-mini-fab class="sidenav__modal__close" color="basic" (click)="closeMoreInfoModal()">
                <mat-icon aria-hidden="false" aria-label="Close more info">close</mat-icon>
            </button>
        </div>

        <div class="sidenav__modal__content">
            <div *ngIf="markerPopupContent == null">
                <modal-content-wrapper [data]="getSelectedElement()"></modal-content-wrapper>
            </div>
        </div>

    </mat-sidenav>

    <!-- Main scrollable content -->
    <mat-sidenav-content>
        <!-- Main map component -->
        
        <div id="map">
            <!--
            <div class="leaflet-bottom" style="left: 50%; margin: 1em;">
                <button class="map-plot-button" mat-raised-button (click)="openBottomSheet()">Vis grafer</button>
            </div>
            -->
            
            <div class="leaflet-bottom" style="right: 2%; margin: 1em">
                <script src="app.component.ts"></script>
            </div>
        </div>

        <div class="leaflet-bottom" style="right: 2%; margin: 1em">
            <button class="map-source-button" mat-raised-button (click)="sourcelink_clicked()">
                <div style="color: rgb(76, 154, 243)">Datakilder</div>
            </button>
        </div>
        


        <!-- Floating layer list -->
        <div id="layer_icons">
            <div>
                <mat-tree [dataSource]="layerTree.dataSource" [treeControl]="layerTree.control" class="tree">
                    <!-- Non-leaf node -->
                    <mat-nested-tree-node *matTreeNodeDef="let node; when layerTree.hasLayers" class="tree_node">

                        <div class="mat-tree-node">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{layerTree.control.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>


                            <mat-checkbox (change)="layerTree.toggleNode(node, true)"
                                [checked]="layerTree.descendantsAllSelected(node)"
                                [indeterminate]="layerTree.descendantsPartiallySelected(node)">
                                <img *ngIf="node.data.icon" [src]="'api/v1/asset/' + node.data.icon">
                                {{ node.data.name }}
                            </mat-checkbox>
                        </div>


                        <div [class.example-tree-invisible]="!layerTree.control.isExpanded(node)" role="group">
                            <ng-container matTreeNodeOutlet></ng-container>



                        </div>
                    </mat-nested-tree-node>

                    <!-- Leaf node -->
                    <mat-tree-node *matTreeNodeDef="let node" class="tree_node">

                        <mat-checkbox (change)="layerTree.toggleNode(node, false)"
                            [checked]="layerTree.selection.isSelected(node)">
                            <img *ngIf="node.data.icon" [src]="'api/v1/asset/' + node.data.icon">
                            {{ node.data.name }}
                        </mat-checkbox>
                    </mat-tree-node>
                </mat-tree>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- Main Graph component call -->
<app-main-graph class="leaflet-bottom" mode="overlay"></app-main-graph>
