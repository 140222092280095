import { JSONUtils } from "./utils/JSONUtils"

export class TMap<K, V> {

    private _map: Map<string, V> = new Map()

    public has(key: K): boolean {
        const k = JSONUtils.sortObject(key)
        return this._map.has(JSON.stringify(k))
    }

    public get(key: K): V {
        const k = JSONUtils.sortObject(key)
        return this._map.get(JSON.stringify(k))
    }

    public set(key: K, value: V): void {
        const k = JSONUtils.sortObject(key)
        this._map.set(JSON.stringify(k), value)
    }

    public size(): number {
        return this._map.size
    }

    public values(): Iterable<V> {
        return this._map.values()
    }

    public entries(): Iterable<[string, V]> {
        return this._map.entries()
    }

    public clear(): void {
        this._map.clear()
    }

    public delete(key: K): void {
        const k = JSONUtils.sortObject(key)
        this._map.delete(JSON.stringify(k))
    }
}
