import { Layer, Marker, DivIcon } from "leaflet";
import { MappingService } from "src/app/services/mapping/mapping.service";
import { ElementBuilder } from "../builder";
import { ElementDTO } from "../dtos/element-dto";
import { InfoModalManager } from "../info-modal-manager";

export class MarkerBuilder implements ElementBuilder {

    constructor(
        private _infoModalManager: InfoModalManager,
        private mappingService: MappingService
    ) { }

    build(element: ElementDTO): Layer {
        let metadata = element.meta
        let coords = element.geo['coords']

        let html = `
            <div style="border-radius: 25px; padding: 0px; text-align: center;">
                <img src="${this.mappingService.getCategoryIcon(metadata['category'])}" width="25" style="pointer-events: none;"/>
            </div>
        `

        let icon = new DivIcon({
            className: "leaflet-data-marker",
            html: html,
            iconSize: [25, 25],
            iconAnchor: [15, 35]
        })

        let marker = new Marker([coords[0], coords[1]], { icon: icon })

        marker.on('click', e => {
            this._infoModalManager.openMoreInfoModal(element)
        })

        return marker
    }

}
