import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PeriodDTO } from 'src/app/models/dtos/period-dto';

@Injectable({
    providedIn: 'root'
})
export class PeriodService {
    /** Leave for now...referenced elsewherte */
    public static readonly DEFAULT: PeriodDTO = {
        id: '',
        name: 'Vælg',
        hours: 0
    }

    constructor(private http: HttpClient) { }

    getPeriods(): Observable<PeriodDTO[]> {
        return new Observable((outer) => {
            this.http.get<PeriodDTO[]>('/api/v1/period/').subscribe(inner => {
                outer.next(inner)
            })
        })
    }
}
