import { WidgetService } from "src/app/services/widget/widget.service"

export class Point {            //Point in a graph
    constructor(
        protected _timestamp: Date,
        protected _value: number
    ) {}

    get timestamp() { return this._timestamp }
    get value() { return this._value }
    getPointAsArray() { return [this._timestamp, this._value] }
}

export class PointSeries {      //Series of Points
    _meta: string[] = []
    _points: Point[]
    _numerator: number = 1
    public unit: string
    constructor(
        public _name: string,
        private _unit: string
    ) {
        this._points = new Array(0)
    }
    getPointSeries() { return this._points }
    getMeta() { return this._meta }
    getUnit() { return this._unit }
    getSeries() {               //Returns series for echarts
        var unitgap = " "
        this.unit = unitgap.concat(this._unit)
        var series = { 
            name: this._name, 
            unit: this.unit,
            type: "line", 
            numerator: this._numerator,
            data: this._points.map(point => point.getPointAsArray() ),
            tooltip: {valueFormatter: value => value + "," + this.unit},
        } 
        return series
    }
    setNumerator(numerator: number) { this._numerator = numerator }
    addPoints(points: Point[]) { this._points = this._points.concat(points) }
    addPoint(point: Point) { this._points.push(point)}
    addMeta(meta: string[]) { this._meta.concat(meta) }
    getValueFromTimestamp(timestamp: Date) {
        return this._points.find(point => point.timestamp.toString() === timestamp.toString())?.value
    }
}

export class PointGroup {     //Group of PointSeries
    private _group: PointSeries[]
    private _widgetService: WidgetService = new WidgetService
    public length: number = 0
    constructor() {
        this._group = new Array(0)
    }
    getSize() { return this._group.length } 
    getWidgetService() { return this._widgetService }
    getPointSeries(index: number) { return this._group[index] }
    getTimestamps(index: number) { return this._group[index].getPointSeries().map(point => point.timestamp) }
    getValues(index: number) { return this._group[index].getPointSeries().map(point => point.value) }
    getUnit(index: number) { return this._group[index].getUnit() }
    getUnitByName(name: string) { return this._group.find(series => series._name === name).getUnit() }
    addPointSeries(series: PointSeries) { this._group.push(series); this.length++ }
    buildSeriesGroup( ) {
        var seriesGroup: any[] = new Array(0)
        for (let i = 0; i < this._group.length; i++) {
            seriesGroup.push(this._group[i].getSeries())
        }
        
        return seriesGroup
    }
}