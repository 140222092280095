import { Component, Input } from '@angular/core';
import { PowerPlant } from '../../elements/powerplant';
import { CardComponent } from '../card-component';

@Component({
  selector: 'app-powerplant',
  templateUrl: './powerplant.component.html',
  styleUrls: ['./powerplant.component.scss']
})
export class PowerplantComponent implements CardComponent {
  @Input('data') data !: PowerPlant;

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["year", ["Årstal", ""]],
    ["company_id", ["Firma ID", ""]],
    ["company_name", ["Firma Navn", ""]],
    ["plant_name", ["Værks navn", ""]],
    ["plant_type_name", ["Værks type", ""]],
    ["zipcode", ["Postnummer", ""]],
    ["main_fuel", ["Primære brændsel", ""]],
    ["capacity_elec_min_kW", ["Elektrisk min kapacitet", "kW"]],
    ["capacity_elec_max_kW", ["Elektrisk max kapacitet", "kW"]],
    ["capacity_heat_min_kW", ["Termisk min kapacitet", "kW"]],
    ["capacity_heat_max_kW", ["Termisk max kapacitet", "kW"]],
    ["capacity_biogas_min_m3h", ["Biogas produktion min kapacitet", "m3/t"]],
    ["capacity_biogas_max_m3h", ["Biogas produktion max kapacitet", "m3/t"]],
  ])

  isMapped(property: string): boolean {
    return this.map.has(property)
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }
}