import { Injectable } from '@angular/core';
import { DomainDTO } from '../models/dtos/domain-dto';
import { Subject } from 'rxjs';
import { Logger, LoggingService } from "src/app/services/logging/logging.service";



// Service used to relay the currently selected domain to other components and a subscribable event (domainChange()) that fires when the domain changes


@Injectable({
  providedIn: 'root'
})
export class CurrentDomainService {
  _domain:        DomainDTO | undefined = undefined           // The current domain - undefined if no domain is selected
  _isSet:         boolean = false                             // True if a domain is selected
  domainChange:   Subject<boolean> = new Subject<boolean>()   // Event that fires when the domain changes

  private _logger: Logger

  constructor(
    private _loggingService: LoggingService,
  ) {
    this._logger = _loggingService.getLogger(CurrentDomainService.name)

   }

  isSet(): boolean {
    return this._isSet
  }

  update(domain: DomainDTO) {     // Update the current domain
    this._domain = domain
    this._isSet = true
    this.domainChange.next(true)
    this._logger.info("New domain chosen: id: " + domain.id + " name: " + domain.name + " group: " + domain.group)
  }

  get(): DomainDTO | undefined {
    return this._domain
  }
}
