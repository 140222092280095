<h2>Anlægsdetaljer</h2>

<style>
    div {
      white-space: pre-wrap;
    }
  </style>

<div *ngFor="let m of data.getProperties() | keyvalue">
    <div *ngIf="isMapped(m.key)">
        <div class="sidenav__modal__content__key">{{ getName(m.key) }}</div>
        <div class="sidenav__modal__content__value">{{ m.value }}{{ getUnit(m.key) }}</div>
    </div>
</div>