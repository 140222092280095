import { BehaviorSubject } from "rxjs";
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { DomainDTO } from "./dtos/domain-dto";
import { LayerDTO } from "./dtos/layer-dto";
import { ElementFactory } from "./element-factory";
import { PolylineBuilder } from "./element-builders/polyline";
import { MarkerBuilder } from "./element-builders/marker";
import { MinimarkerBuilder } from "./element-builders/minimarker";
import { ElementService } from "../services/element/element.service";
import { Logger, LoggingService } from "../services/logging/logging.service";
import { InfoModalManager } from "./info-modal-manager";
import { MappingService } from "../services/mapping/mapping.service";
import { TileLayerBuilder } from "./element-builders/tilelayer";
import { PolygonBuilder } from "./element-builders/polygon";

class LayerMapping {
    dto: LayerDTO
    layer: L.FeatureGroup
    elements: L.FeatureGroup
}

export class LayerManager {
    private _update: BehaviorSubject<string> = new BehaviorSubject(undefined)

    private _baseLayer: L.FeatureGroup = new L.FeatureGroup()
    private _layers: Map<string, LayerMapping> = new Map()
    private _domain: DomainDTO | undefined = undefined

    private _factory = new ElementFactory()

    private _logger: Logger

    constructor(
        private _elementService: ElementService,
        private _loggingService: LoggingService,
        private _infoModalManager: InfoModalManager,
        private _mappingService: MappingService) {

        this._logger = _loggingService.getLogger(LayerManager.name)

        this._factory.register('polyline',  new PolylineBuilder())
        this._factory.register('polygon',  new PolygonBuilder(this._infoModalManager, this._mappingService))
        this._factory.register('marker',    new MarkerBuilder(this._infoModalManager, this._mappingService))
        this._factory.register('minimarker', new MinimarkerBuilder(this._infoModalManager, this._mappingService))
        this._factory.register('tilelayer', new TileLayerBuilder())
    }

    clear(): void {
        this._layers.clear()
    }

    add(layer: LayerDTO): void {
        let mapping: LayerMapping =  {dto: layer, layer: new L.FeatureGroup(), elements: new L.FeatureGroup() }

        let elements = layer.clustered ? L.markerClusterGroup({
            iconCreateFunction: (cluster) => {
                let html = `
                    <div style="border-radius: 25px; padding: 0px; text-align: center;">
                        <img src="api/v1/asset/${layer.icon}" width="25" style="pointer-events: none;"/>
                        <span>${cluster.getChildCount()}</span>
                    </div>`

                let icon = new L.DivIcon({
                    className: "leaflet-data-marker",
                    html: html,
                    iconSize: [25, 25],
                    iconAnchor: [15, 35]
                })

                return icon
            }
        }): new L.FeatureGroup()

        mapping.layer.addLayer(elements)
        mapping.elements = elements

        this._layers.set(layer.id, mapping)
        this._baseLayer.addLayer(mapping.layer)

        this.loadLayer(mapping)
    }

    remove(layer: LayerDTO): void {
        if (this._layers.has(layer.id)) {
            const mapping: LayerMapping = this._layers.get(layer.id)

            this._baseLayer.removeLayer(mapping.layer)
            this._layers.delete(layer.id)

            this._update.next(`Layer unloaded (${mapping.dto.id})`)
        }
    }

    private loadLayer(mapping: LayerMapping) {
        mapping.elements.clearLayers()

        if (this._domain) {
            this._elementService.getElements(mapping.dto, this._domain).subscribe(dtos => {
                dtos.forEach(dto => {
                    const element: L.Layer | null = this._factory.build(dto)

                    if (element) {
                        mapping.elements.addLayer(element)
                    }
                })

                this._update.next(`Layer loaded (${mapping.dto.id})`)
            })
        } else {
            this._logger.info('Domain not set, doing nothing')
        }
    }

    private reloadLayers(): void {
        for (let mapping of this._layers.values()) {
            this.loadLayer(mapping)
        }
    }

    public get update() { return this._update }
    public get baseLayer() { return this._baseLayer }
    public get bounds() { return this._baseLayer.getBounds() }
    public get domain() { return this._domain }

    public set domain(value: DomainDTO) {
        this._domain = value
        this.reloadLayers()
    }
}
