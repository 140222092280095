import { Component, Input, OnInit } from '@angular/core';
import { PublicBuilding } from '../../elements/publicbuilding';
import { CardComponent } from '../card-component';
import { transition, trigger, animate, style } from '@angular/animations';
import { EChartsOption } from 'echarts';
import { Logger, LoggingService } from "src/app/services/logging/logging.service";
import { DataService } from 'src/app/services/data/data.service';
import { Source } from 'src/app/models/object-classes/Source';
import { NONE_TYPE } from '@angular/compiler';


@Component({
    selector: 'app-publicbuilding',
    templateUrl: './publicbuilding.component.html',
    styleUrls: ['./publicbuilding.component.scss'],
    animations: [
        trigger('showGraphContainer', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('400ms ease-in', style({ transform: 'translateY(0%)' }))
            ]),
            transition(':leave', [
                animate('400ms ease-in', style({ transform: 'translateY(100%)' }))
            ])
        ])
    ]
})

export class PublicBuildingComponent implements CardComponent {
    private static readonly DATA_INTERVAL_IN_HOURS: number = 18993 // = 26 months in hours
    private _logger: Logger

    graphOpen:              boolean = false
    button_height:          number  = 85
    graph_height:           number  = 200
    number_of_graphs:       number  = 0
    graph_container_height: number
    graph_container_bottom: number
    dar_address:            string  = ''
    data_categories:        any     = null

    data_sources:           Source[]= []
    data_sets:              Graphdata[] = []
    

    @Input('data') data !: PublicBuilding;

    private map: Map<string, [string, string]> = new Map<string, [string, string]>([
        ["name", ["Navn", ""]],
        ["address", ["Adresse", ""]],
        ["use", ["Kategori", ""]],
        ["label", ["Energimærke", ""]],
        ["size", ["Areal", "m2"]],
        ["built", ["Bygget", ""]],
        ["renovated", ["Renoveret", ""]],
    ])

    constructor(private _dataService: DataService, _loggingService: LoggingService) {
        this._logger = _loggingService.getLogger(PublicBuildingComponent.name)
    }

    isMapped(property1: string, property2: string | number | any): boolean {
        if (property1 == 'dar_address_id' && this.dar_address == '') {
            this.dar_address = property2
            this.set_data_sources()
        } 
        if ((this.number_of_graphs == 0) && (this.data_sources.length > 0)) {
            this.update_data()
        }

        return this.map.has(property1)
    }

    set_data_sources(): void {
        var type: string = "dar_address_id"
        this._dataService.getSources(
            type, 
            this.dar_address
        ).subscribe(data => {
            let mapped: Source[] = data.map(v => new Source(type, v.dar_address_id, v.category, v.data_source, v.process_type))
            this.data_sources = mapped
        })
    }

    update_data(): void {
        for (let i = 0; i < this.data_sources.length; i++) {
            //this._logger.info("dar_address_id:" + this.data_sources[i].getDomain().getDomain_code() )
            const source = { 
                dar_address_id: this.data_sources[i].getDomain().getDomain_code(), 
                data_source: this.data_sources[i].getData_source(), 
                process_type: this.data_sources[i].getProcess_type(), 
                category: this.data_sources[i].getCategory()
            }
            const graphdata = new Graphdata()
            let data_type: string = ""
            let data_unit: string = ""
            let numerator: number = 1
            if (source.data_source == "electricity" && source.process_type == "consumption") {data_type = "elforbrug", data_unit = "kWt", numerator = 1000}
            else if (source.data_source == "electricity" && source.process_type == "production") {data_type = "elproduktion", data_unit = "kWt", numerator = 1000}
            else if (source.data_source == "water" && source.process_type == "consumption") {data_type = "vandforbrug", data_unit = "m3"}
            else if (source.data_source == "heat" && source.process_type == "consumption") {data_type = "varmeforbrug", data_unit = "kWt", numerator = 1000}
            else {data_type = "unknown", data_unit = "unknown"}
            {
                this._dataService.getLowResData( source, PublicBuildingComponent.DATA_INTERVAL_IN_HOURS 
                    ).subscribe(data => {
                        let mapped: Item[] = data.map(v => new Item(new Date(v.timestamp).getFullYear(), new Date(v.timestamp).getMonth(), v.value))
                        graphdata.addItems(mapped.reverse())
                        graphdata.setGraphoption(data_type, data_unit, numerator)
                        graphdata.setEchartsoption()
                        this.data_sets.push(graphdata)
                    })
            }
        }
        //this._logger.info("Data sources: "+ this.data_sources.length + " Data sets: " + this.data_sets.length)
        this.number_of_graphs = this.data_sources.length
        this.graph_container_height = this.button_height + (this.number_of_graphs * this.graph_height)
        this.graph_container_bottom = -1 * this.graph_container_height
    }

    getName(property: string): string {
        return this.map.get(property)[0]
    }

    getUnit(property: string): string {
        return " " + this.map.get(property)[1]
    }

    graphClosed() {
        return !this.graphOpen
    }

    graphOpened() {
        return this.graphOpen
    }

    openGraph() {
        this.graphOpen = true
        this.graph_container_bottom = 0
    }

    closeGraph() {
        this.graphOpen = false
        this.graph_container_bottom = -1 * this.graph_container_height
    }
}



class Graphdata {    
    private _itemgroup: ItemGroup
    private _echartsoption: EChartsOption
    private _graphoption: GraphOptions
    private _numerator: number = 1
    constructor(
    ) {
        this._itemgroup = new ItemGroup
    }
    getItemgroup() { return this._itemgroup }
    getEchartsoption() { return this._echartsoption }
    getGraphoption() { return this._graphoption }
    setItemgroup(itemgroup: ItemGroup) { this._itemgroup = itemgroup }
    setEchartsoption() { this._echartsoption = this._graphoption.getGraphOptions() }
    setGraphoption(title: string, unit: string, numerator: number) { 
        this._graphoption = new GraphOptions(title, unit, this._itemgroup.buildDataSet(), numerator) 
    }
    addItems(items: Item[]) { this._itemgroup.addItems(items) }
}


class Item {
    private _month_text: string
    private _month: number
    constructor(
        private _year: number,
        private _pre_month: number,
        private _value: number,
    ) {
        this._month = this._pre_month + 1
        this._month_text = this.writeMonth()
    }
    getYear() {
        return this._year
    }
    getMonth() { return this._month }
    getMonthtext() { return this._month_text }
    getValue() { return this._value }

    writeMonth(): string {
        let months: string[] = ["", "Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"]
        return months[this._month]
    }
}

class ItemSeries {      //Items for 1 year
    private _items: Item[]
    constructor(
        private _year: number
    ) {
        this._items = new Array(13)
    }
    getYear() {
        return this._year
    }
    getItems() { return this._items }
    addItem(item: Item) {
        this._items[item.getMonth()] = item
    }
}

class ItemGroup {
    private _group: ItemSeries[]     //[0] = this year, [1] = previous year, and so on.
    private _thisYear: number
    constructor() {
        this._thisYear = new Date().getFullYear()
        this._group = new Array(1)
        this._group[0] = new ItemSeries(this._thisYear)
    }
    expandGroup(newSize: number) {
        for (let i = this._group.length; i < newSize; i++) {
            this._group.push(new ItemSeries(this._thisYear - i))
        }
    }
    addItems(items: Item[]) {
        for (let i = 0; i < items.length; i++) {
            if ((this._thisYear - items[i].getYear() + 1) > this._group.length) {
                this.expandGroup(1 + this._thisYear - items[i].getYear())
            }
            this._group[(this._thisYear - items[i].getYear())].addItem(items[i])
        }
    }
    buildDataSet(): any {
        var years: (string | number)[] = new Array(this._group.length)
        let month_names: string[] = ["", "Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"]
        var months: any[] = new Array(13)
        for (let i = 0; i < months.length; i++) {
            months[i] = new Array(this._group.length + 1)
        }
        years[0] = 'category'
        var year: number = 1
        var months_set: boolean = false
        for (let i = (this._group.length - 1); i >= 0; i--) {  // for Itemseries (year), oldest to newest
            var series: Item[] = this._group[i].getItems()     // the Itemseries currently used
            years[year] = this._group[i].getYear().toString()
            for (let j = 1; j < months.length; j++) {            // for month of the Itemseries
                if (!months_set) {
                    months[j][0] = month_names[j]
                }
                if (series[j] instanceof Item) {
                    months[j][year] = series[j].getValue()
                }
            }
            months_set = true
            year++
        }
        months[0] = years
        return months
    }
}

class GraphOptions {
    private _options: any
    private _visual: any
    private _visuals: any[]
    constructor(
        private _title: string,
        private _unit: string,
        private _data: any[][],
        private _numerator: number = 1
    ) {
        this._visual = { type: 'bar' }
        this._visuals = new Array(this._data[0].length - 1)
        for (let i = 0; i < this._visuals.length; i++)
            this._visuals[i] = this._visual
        this._options = {
            title: {
                text: this._title,
                left: "center",
                top: "auto",
                textStyle: {
                    fontSize: 16
                },
            },
            legend: {
                top: '22px',
                right: '10px'
            },
            tooltip: {},
            dataset: {
                // Provide a set of data.
                source: this._data
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    textBorderType: 'dashed',
                    borderWidth: 10,
                    fontSize: 9,
                    overflow: 'truncate',
                    width: 120,
                    interval: 0,
                    rotate: 30 //If the label names are too long you can manage this by rotating the label.
                },
            },
            axisTick: { alignWithLabel: true, },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function (value: any) {
                        return value/0.1 + _unit;
                    },
                    fontSize: 8,
                },
            },
            series: this._visuals,
            grid: { left: '3%', right: '4%', bottom: '3%', top: '30%', containLabel: true, },
        };
    }

    setTitle(title: string) {
        this._title = title
        this._options.title.text = this._title
    }

    setContent(content: any[][]) {
        this._data = content
        this._options.series.data = this._data
    }

    getGraphOptions(): EChartsOption {
        return this._options
    }
}
