import { Component, Input, OnInit } from '@angular/core';
import { WindTurbine } from '../../elements/windturbine';
import { CardComponent } from '../card-component';

@Component({
  selector: 'app-windturbine',
  templateUrl: './windturbine.component.html',
  styleUrls: ['./windturbine.component.scss']
})
export class WindturbineComponent implements CardComponent {
  @Input('data') data !: WindTurbine;

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["gsrn", ["GSRN", ""]],
    ["connection_date", ["Opsættelses dato", ""]],
    ["capacity_kW", ["Kapacitet", "kW"]],
    ["rotor_diameter_m", ["Rotor diameter", "m"]],
    ["hub_height_m", ["Nacelle højde", "m"]],
    ["manufacturer", ["Producent", ""]],
    ["model", ["Model", ""]],
    ["owner", ["Ejer", ""]],
    ["cadastre_no", ["Matrikel nr.", ""]],
    ["grid_company_inst_no", ["Netselskabs instnr.", ""]],
  ])

  isMapped(property: string): boolean {
    return this.map.has(property)
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }
}
