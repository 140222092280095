import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainDTO as DomainDTO } from 'src/app/models/dtos/domain-dto';

export interface DomainGroup {
    name: string
    domains: DomainDTO[]
}

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  constructor(private http: HttpClient) { }

  queryDomainsByName(query: string): Observable<DomainGroup[]> {
    if (!query) { return of([]) }

    const options = query ? { params: new HttpParams().set('query', query) } : {};

    return this.http.get<DomainDTO[]>('/api/v1/domain', options).pipe(
        map(domains => {

            let groups = new Map<string, DomainDTO[]>()

            domains.forEach(domain => {
                groups.set(domain.group, (groups.get(domain.group) || []).concat(domain))
            })

            return Array.from(groups).map(g => { return { name: g[0], domains: g[1] }})
        })
    )
  }

  getDomainByCode(code: string): Observable<DomainDTO> {
    const options = code ? { params: new HttpParams().set('code', code) } : {};

    return this.http.get<DomainDTO>(`/api/v1/domain`, options)
  }
}
