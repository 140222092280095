import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkPlotComponent } from './controls/cdk-plot/cdk-plot.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from './controls/bottom-sheet/bottom-sheet.component';
import { WindturbineComponent } from './models/cards/windturbine/windturbine.component';
import { PowerplantComponent } from './models/cards/powerplant/powerplant.component';
import { UnknownComponent } from './models/cards/unknown/unknown.component';
import { WrapperComponent } from './models/wrapper/wrapper.component';
import { CardDirective } from './models/cards/card-directive';
import { PowerToXComponent } from './models/cards/powertox/powertox.component';
import { LivingLabComponent } from './models/cards/livinglab/livinglab.component';
import { PublicBuildingComponent } from './models/cards/publicbuilding/publicbuilding.component';
import { DHCoverageComponent } from './models/cards/dhcoverage/dhcoverage.component';
import { GasCoverageComponent } from './models/cards/gascoverage/gascoverage.component';
import { WWCoverageComponent } from './models/cards/wwcoverage/wwcoverage.component';
import { WaterPlantComponent } from './models/cards/waterplant/waterplant.component';
import { WaterCoverageComponent } from './models/cards/watercoverage/watercoverage.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { NgxEchartsModule } from 'ngx-echarts';
import { MainGraphComponent } from './controls/main-graph/main-graph.component';

@NgModule({
  declarations: [
    AppComponent,
    CdkPlotComponent,
    BottomSheetComponent,
    WindturbineComponent,
    PowerplantComponent,
    UnknownComponent,
    WrapperComponent,
    CardDirective,
    PowerToXComponent,
    LivingLabComponent,
    PublicBuildingComponent,
    DHCoverageComponent,
    GasCoverageComponent,
    WWCoverageComponent,
    WaterPlantComponent,
    WaterCoverageComponent,
    MainGraphComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSelectModule,
    MatSliderModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,

    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),

  ],
  providers: [
    { provide: MatBottomSheet },
    { provide: LOCALE_ID, useValue: 'da-DK'}
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
