import { Component, Input } from '@angular/core';
import { LivingLab } from '../../elements/livinglab';
import { CardComponent } from '../card-component';

@Component({
  selector: 'app-powerplant',
  templateUrl: './livinglab.component.html',
  styleUrls: ['./livinglab.component.scss']
})
export class LivingLabComponent implements CardComponent {
  @Input('data') data !: LivingLab;

  private map: Map<string, [string, string]> = new Map<string, [string, string]>([
    ["name", ["Navn", ""]],
    ["units", ["Husstande", ""]],
    ["company_name", ["Firma Navn", ""]],
    ["focus", ["Fokus", ""]],
    ["partners", ["Partnere", ""]],
    ["link", ["Link", ""]],
  ])
  
  isMapped(property: string): boolean {
    return this.map.has(property)
  }

  getName(property: string): string {
    return this.map.get(property)[0]
  }

  getUnit(property: string): string {
    return " " + this.map.get(property)[1]
  }
}